import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Col, Row } from 'antd';
import { BTNPrimary } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { format } from 'src/utils';
import { ACTION_TYPES, actionGetNFTLog } from '../redux/Account.action';

const tierList = [
    {
        badge: null,
        label: 'TIER RANKING LEVEL',
        value: 0,
        info: [
            'Total trading volume',
            'ILA Tier',
            'NFT Trading Fee Discount',
            'Allocation Volume Multiplication <br/><span style="font-style: normal; font-weight: 400; font-size: 12px; color: #ffffff;">(When you have allocation from having ILA tier)</span>',
            'Free Artwork Airdrops',
            'Additional benefits',
        ],
        processBar: '2%',
    },
    {
        badge: null,
        label: 'RANK C',
        value: 10,
        info: [
            '> 10 BNB',
            '-',
            '<span style="font-weight: 600; font-size: 16px;">10%</span> discount',
            '<span style="font-weight: 600; font-size: 16px;">x 1.1</span> <br/>',
            '-',
            '-',
        ],
        processBar: '25%',
    },
    {
        badge: null,
        label: 'RANK B',
        value: 50,
        info: [
            '> 50 BNB',
            '-',
            '<span style="font-weight: 600; font-size: 16px;">15%</span> discount',
            '<span style="font-weight: 600; font-size: 16px;">x 1.2</span> <br/>',
            '-',
            '-',
        ],
        processBar: '50%',
    },
    {
        badge: null,
        label: 'RANK A',
        value: 200,
        info: [
            '> 200 BNB',
            'Gold or Platinum',
            '<span style="font-weight: 600; font-size: 16px;">20%</span> discount',
            '<span style="font-weight: 600; font-size: 16px;">x 1.5</span> <br/>',
            'Surprised Artwork Airdrops',
            'Chance to redeem physical artworks',
        ],
        processBar: '75%',
    },
    {
        badge: null,
        label: 'RANK S',
        value: 1000,
        info: [
            '> 1000 BNB',
            'Platinum',
            '<span style="font-weight: 600; font-size: 16px;">30%</span> discount',
            '<span style="font-weight: 600; font-size: 16px;">x 2</span> <br/>',
            'Exclusive 1/1 Artwork Drops',
            'Invitation to exclusive events',
        ],
        processBar: '100%',
    },
];

const tierListCustomMobile: any = {
    values: [10, 50, 200, 1000],
    title: [
        'Total trading volume',
        'ILA Tier',
        'NFT Trading Fee Discount',
        'Allocation Volume Multiplication',
        'Free Artwork Airdrops',
        'Additional benefits',
    ],
    ranks: ['C', 'B', 'A', 'S'],
    C: ['> 10 BNB', '-', '10% discount', 'x 1.1', '-', '-'],
    B: ['> 50 BNB', '-', '15% discount', 'x 1.2', '-', '-'],
    A: [
        '> 200 BNB',
        'Gold or Platinum',
        '20% discount',
        'x 1.5',
        'Surprised Artwork Airdrops',
        'Chance to redeem physical artworks',
    ],
    subTitle: [null, null, null, '(When you have allocation from having ILA tier)', null, null],
    S: [
        '> 1,000 BNB',
        'Platinum',
        '30% discount',
        'x 2',
        'Exclusive 1/1 Artwork Drops',
        'Invitation to exclusive events',
    ],
};

const MyWallet = React.memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { account } = useActiveWeb3React();
    const { tierClub }: any = useSelector((state: any) => state.account);

    useEffect(() => {
        if (!account) {
            dispatch({ type: ACTION_TYPES.CLEAR_TRADING_VOLUME });
            return;
        }
        dispatch(actionGetNFTLog(account));
    }, [account]);

    const processPercent = () => {
        let processBar = '2%';
        if (tierClub.tradingVolume >= 10 && tierClub.tradingVolume < 50) {
            processBar = '25%';
        }
        if (tierClub.tradingVolume >= 50 && tierClub.tradingVolume < 200) {
            processBar = '50%';
        }
        if (tierClub.tradingVolume >= 200 && tierClub.tradingVolume < 1000) {
            processBar = '75%';
        }
        if (tierClub.tradingVolume >= 1000) {
            processBar = '100%';
        }
        return processBar;
    };

    const getLabelTier = (tier: any, tradingVolume: any) => {
        switch (tier.value) {
            case 0:
                return <span className="fs-suppersmall fw-bold tier-value">{tier.label}</span>;
            case 10:
                if (tradingVolume >= 10 && tradingVolume < 50) {
                    return <span className="tier-nft-gradient">{tier.label}</span>;
                }
                return <span className="tier-nft fs-suppersmall fw-bold">{tier.label}</span>;
            case 50:
                if (tradingVolume >= 50 && tradingVolume < 200) {
                    return <span className="tier-nft-gradient">{tier.label}</span>;
                }
                return <span className="tier-nft fs-suppersmall fw-bold">{tier.label}</span>;
            case 200:
                if (tradingVolume >= 200 && tradingVolume < 1000) {
                    return <span className="tier-nft-gradient">{tier.label}</span>;
                }
                return <span className="tier-nft fs-suppersmall fw-bold">{tier.label}</span>;
            default:
                if (tradingVolume >= 1000) {
                    return <span className="tier-nft-gradient">{tier.label}</span>;
                }
                return <span className="tier-nft fs-suppersmall fw-bold">{tier.label}</span>;
        }
    };
    const getLabelTierMobile = (label: any, value: any, tradingVolume: any) => {
        switch (value) {
            case 0:
                return <span className="tier-value">RANK {label}</span>;
            case 10:
                if (tradingVolume >= 10 && tradingVolume < 50) {
                    return <span className="tier-nft-gradient-mobile">RANK {label}</span>;
                }
                return <span className="tier-value">RANK {label}</span>;
            case 50:
                if (tradingVolume >= 50 && tradingVolume < 200) {
                    return <span className="tier-nft-gradient-mobile">RANK {label}</span>;
                }
                return <span className="tier-value">RANK {label}</span>;
            case 200:
                if (tradingVolume >= 200 && tradingVolume < 1000) {
                    return (
                        <div className="tier-nft-gradient-mobile tier-private">
                            <span>RANK {label}</span>
                            <span className="fs-superSmall fw-light tier-value-private"> Private NFT Club</span>
                        </div>
                    );
                }
                return (
                    <div className="tier-value tier-private">
                        <span>RANK {label}</span>
                        <span className="fs-superSmall fw-light tier-value-private"> Private NFT Club</span>
                    </div>
                );
            default:
                if (tradingVolume >= 1000) {
                    return (
                        <div className="tier-nft-gradient-mobile tier-private">
                            <span>RANK {label}</span>
                            <span className="fs-superSmall fw-light tier-value-private"> Private NFT Club</span>
                        </div>
                    );
                }
                return (
                    <div className="tier-value tier-private">
                        <span>RANK {label}</span>
                        <span className="fs-superSmall fw-light tier-value-private"> Private NFT Club</span>
                    </div>
                );
        }
    };
    const getClassActive = (tier: any, tradingVolume: any) => {
        switch (tier.value) {
            case 0:
                return 'tier-nft-list-header';
            case 10:
                if (tradingVolume >= 10 && tradingVolume < 50) {
                    return 'tier-nft-list active';
                }
                return 'tier-nft-list';
            case 50:
                if (tradingVolume >= 50 && tradingVolume < 200) {
                    return 'tier-nft-list active';
                }
                return 'tier-nft-list';
            case 200:
                if (tradingVolume >= 200 && tradingVolume < 1000) {
                    return 'tier-nft-list active';
                }
                return 'tier-nft-list';
            default:
                if (tradingVolume >= 1000) {
                    return 'tier-nft-list active';
                }
                return 'tier-nft-list';
        }
    };
    const getClassActiveMobile = (tier: any, tradingVolume: any) => {
        switch (tier?.value) {
            case 0:
                return '';
            case 10:
                if (tradingVolume >= 10 && tradingVolume < 50) {
                    return 'active';
                }
                return '';
            case 50:
                if (tradingVolume >= 50 && tradingVolume < 200) {
                    return 'active';
                }
                return '';
            case 200:
                if (tradingVolume >= 200 && tradingVolume < 1000) {
                    return 'active';
                }
                return '';
            default:
                if (tradingVolume >= 1000) {
                    return 'active';
                }
                return '';
        }
    };

    const onClickToYourNft = () => {
        history.push(`/collection/bony-bastards?status=owned`);
    };
    return (
        <Row>
            <Col className="nft-club-mobile" xs={{ span: 24 }} lg={{ span: 24, offset: 0 }}>
                <Row className="row-content profile">
                    <Col xs={24} lg={14} className="wallet">
                        <span className="fw-bold fm-ob fs-26">Your NFT Club Profile</span>
                        <BTNPrimary
                            className="btn-connect-wallet fs-small fw-medium"
                            title="Click to view your NFTs"
                            onClick={() => onClickToYourNft()}
                        />
                    </Col>
                    <Col xs={24} lg={10}>
                        <Row className="nft-info-mobile">
                            <Col lg={10} xs={24} className="nft-info">
                                <div className="nft-info-label">
                                    <span className="fs-suppersmal fw-bold">TOTAL TRADING VOLUME</span>
                                    <span className="value fw-suppermedium fs-mediumlarge">
                                        {tierClub.tradingVolume ?? 0} BNB
                                    </span>
                                </div>
                            </Col>
                            <Col lg={12} xs={24} className="nft-info">
                                <div className="nft-info-label">
                                    <span className="fs-suppersmal fw-bold">RANKING ON LEADER BOARD</span>
                                    <span className="value fw-suppermedium fs-mediumlarge">#{tierClub.rank ?? 0}</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="nft-club-custom-mobile">
                    <Col xs={24} className="title-header fw-medium">
                        Private NFT Club
                    </Col>
                    <Col className="tier-process-bar">
                        <Row className="tier-process-complete" style={{ width: `${processPercent()}` }} />
                    </Col>
                    <Col>
                        {tierListCustomMobile.ranks.map((rank: string, idx: number) => {
                            return (
                                <Col xs={24} className="nft-mobile-header">
                                    <Row
                                        className={`nft-mobile-content ${getClassActiveMobile(
                                            tierList[idx + 1],
                                            tierClub.tradingVolume,
                                        )}`}
                                    >
                                        <Col xs={24} className="header">
                                            {getLabelTierMobile(
                                                rank,
                                                tierListCustomMobile.values[idx],
                                                tierClub.tradingVolume,
                                            )}
                                        </Col>
                                        {tierListCustomMobile[rank].map((value: string, index: number) => {
                                            return (
                                                <>
                                                    <Col xs={12} className="description">
                                                        {format.amount(tierListCustomMobile.title[index])}
                                                        <p className="sub-description">
                                                            {tierListCustomMobile.subTitle[index]}
                                                        </p>
                                                    </Col>
                                                    <Col xs={12} className="title">
                                                        {value}
                                                    </Col>
                                                </>
                                            );
                                        })}
                                    </Row>
                                </Col>
                            );
                        })}
                    </Col>
                </Row>
                <Row className="row-content nft-club-custom-desktop">
                    <Col className="tier tier-show">
                        <Row>
                            <span className="tier-show-title fw-bold">Your NFT Club</span>
                        </Row>
                        <Row className="tier-process-bar">
                            <Row className="tier-process-complete" style={{ width: `${processPercent()}` }} />
                        </Row>
                        <Row className="nft-club-private">
                            <span className="fs-small fw-medium">Private NFT Club</span>
                        </Row>
                        <Row className="row-content-scroll tier-list nft-club-list">
                            {tierList.map((tier) => {
                                return (
                                    <Col className={getClassActive(tier, tierClub.tradingVolume)} key={tier.label}>
                                        <Row>
                                            <Col
                                                className={`
                                            ${tier.value !== 0 && 'header-tier'}
                                            ${tier.value === 0 && 'header-tier-first'}`}
                                            >
                                                {getLabelTier(tier, tierClub.tradingVolume)}
                                            </Col>
                                        </Row>
                                        {tier.info.map((info, idx) => {
                                            const key = idx + 1;
                                            return idx === 3 ? (
                                                <span
                                                    key={`${tier.label}-${key}`}
                                                    className={`fs-regular fw-medium tier-info-title-width
                                                ${tier.value !== 0 && 'tier-info-value'}
                                                ${tier.value === 0 && 'tier-min-width'}`}
                                                >
                                                    {ReactHtmlParser(info)}
                                                </span>
                                            ) : (
                                                <span
                                                    key={`${tier.label}-${key}`}
                                                    className={`fs-regular fw-medium tier-info-title
                                                ${tier.value !== 0 && 'tier-info-value'}
                                                ${tier.value === 0 && 'tier-min-width'}`}
                                                >
                                                    {ReactHtmlParser(info)}
                                                </span>
                                            );
                                        })}
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

export default MyWallet;
