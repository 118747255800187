/* eslint-disable react/jsx-fragments */
import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

const ignoreScrolls = ['collection', 'marketplaces', 'nft-analytics'];
function ScrollToTop({ history, children }: any) {
    useEffect(() => {
        const listen = history.listen((event: any) => {
            if (!ignoreScrolls.find((e) => e === event.pathname.split('/')[1])) {
                window.scrollTo(0, 0);
            }
        });
        return () => {
            listen();
        };
    }, []);

    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
