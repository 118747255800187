import { Action } from 'redux';

export enum AirdropActionType {
    LOADING = 'AIRDROP/LOADING',
    SET_INFO_TOKEN_SEND = 'AIRDROP/SET_INFO_TOKEN_SEND',
    SET_RECIPIENT = 'AIRDROP/SET_RECIPIENT',
    SET_ERROR = 'AIRDROP/SET_ERROR',
    SET_TYPE_TOKEN = 'AIRDROP/SET_TYPE_TOKEN',
}

export interface IRecipient {
    Address: string;
    Amount: string;
    Sended?: boolean;
}

export interface IInfoTokenSend {
    address: string;
    isValidErc20: boolean;
    symbol: string;
    balance: string;
    decimals: number;
}

export enum ETypeToken {
    ERC20 = 'erc20',
    ERC721 = 'erc721',
    ERC1155 = 'erc1155',
}
export interface IAirdropState {
    infoTokenSend: IInfoTokenSend;
    recipients: IRecipient[];
    typeToken: ETypeToken;
    loading: boolean;
    errorMsg: string;
}

//----------------------------------------------
// Payload Definition here!
//----------------------------------------------

export interface AirdropTokenSendPayload {
    infoTokenSend: IInfoTokenSend;
}

export interface AirdropRecipientsPayload {
    recipients: IRecipient[];
}

export interface AirdropLoadingPayload {
    loading: boolean;
}

export interface AirdropErrorPayload {
    errorMsg: string;
}

export interface AirdropTypeTokenPayload {
    typeToken: ETypeToken;
}
//----------------------------------------------
// Action Definition here!
//----------------------------------------------

export interface AirdropSetLoadingAction extends Action {
    type: AirdropActionType.LOADING;
    payload: AirdropLoadingPayload;
}

export interface AirdropSetTokenSendAction extends Action {
    type: AirdropActionType.SET_INFO_TOKEN_SEND;
    payload: AirdropTokenSendPayload;
}

export interface AirdropSetRecipientAction extends Action {
    type: AirdropActionType.SET_RECIPIENT;
    payload: AirdropRecipientsPayload;
}

export interface AirdropSetErrorAction extends Action {
    type: AirdropActionType.SET_ERROR;
    payload: AirdropErrorPayload;
}
export interface AirdropSetTypeTokenAction extends Action {
    type: AirdropActionType.SET_TYPE_TOKEN;
    payload: AirdropTypeTokenPayload;
}

export type AirdropActions =
    | AirdropSetLoadingAction
    | AirdropSetRecipientAction
    | AirdropSetTokenSendAction
    | AirdropSetErrorAction
    | AirdropSetTypeTokenAction;
