import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import AOS from 'aos';
import { Router } from 'react-router';
import { MainRoute } from 'src/modules';
import { ThemeProvider, ThemedGlobalStyle } from 'src/theme';
import Header from 'src/components/Header';
import Modal from 'src/components/Modal';
import Footer from 'src/components/Footer';
import Loading from 'src/components/Loading';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import SolanaAdapterProvider from 'src/components/SolanaAdapterProvider';
import AptosProvider from 'src/components/AptosProvider';

import dynamic from 'next/dynamic';
import Web3ReactManager from '../components/Header/Web3ReactManager';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import withApp from './App.enhance';
import './reset.scss';
import 'antd/dist/antd.css';
import 'aos/dist/aos.css';
import '@solana/wallet-adapter-ant-design/styles.css';

const history = createBrowserHistory();
const Web3ProviderNetwork = dynamic(() => import('src/components/Web3ProviderNetwork'), { ssr: false });

function getLibrary(provider: any) {
    return new Web3Provider(provider);
}

const App: React.FunctionComponent = () => {
    const [global, setGlobal] = useState(true);
    useEffect(() => {
        const search = window.location.pathname.toString().search('iframe/nft-ido/');
        if (search !== -1) {
            setGlobal(false);
        }

        window.addEventListener('error', (e) => {
            if (/Loading chunk [\d]+ failed/.test(e.message)) {
                // eslint-disable-next-line no-alert
                alert('A new vers   ion released. Need to reload the page to apply changes.');
                window.location.reload();
            }
        });
        AOS.init({
            // disable: 'mobile',
            duration: 2000,
        });
    }, []);
    return (
        <SolanaAdapterProvider>
            <AptosProvider>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <Web3ProviderNetwork getLibrary={getLibrary}>
                        <Web3ReactManager>
                            <ThemeProvider>
                                <ThemedGlobalStyle />
                                <Router history={history}>
                                    <ScrollToTop>
                                        {global && <Header />}
                                        <MainRoute />
                                        {global && <Footer />}
                                        <Modal />
                                        <Loading />
                                    </ScrollToTop>
                                </Router>
                            </ThemeProvider>
                        </Web3ReactManager>
                    </Web3ProviderNetwork>
                </Web3ReactProvider>
            </AptosProvider>
        </SolanaAdapterProvider>
    );
};

export default withApp(React.memo(App));
