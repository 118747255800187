import { Action } from 'redux';

export enum InoActionType {
    SET_FETCHING = 'INO/FETCHING_DATA',
    SET_ALL_INO_POOL = 'INO/SET_ALL_INO_POOL',
}

export interface BoxesIno {
    id: number;
    inoId: number;
    type: number;
    title: string;
    description: string;
    image: string;
    icon: string;
    parentBoxId: any;
    boxes?: BoxesIno[];
}
export interface InoItem {
    id: number;
    title: string;
    slug: string;
    description: string;
    logo: string;
    thumb: string;
    image: string;
    saleTitle: string;
    category: number;
    status: number;
    networkName: string;
    networkId: number;
    contractSymbol: string;
    contractId: string;
    tokenAddress: string;
    decimals: number;
    price: string;
    openTime: string;
    stockLeft: number;
    totalStock?: number;
    boxes: BoxesIno[];
}

export interface InoState {
    isFetching: boolean;
    currentInos: InoItem[];
    upComingInos: InoItem[];
    fundedInos: InoItem[];
}

// ----------------------------------------------
//  Payloads Definition here!
// ----------------------------------------------

export interface InoSetFetchingPayload {
    isFetching: boolean;
}
export interface InoSetAllPoolPayload {
    currentInos: InoItem[];
    upComingInos: InoItem[];
    fundedInos: InoItem[];
}

// ----------------------------------------------
//  Actions Definition here!
// ----------------------------------------------

export interface InoSetFetchingAction extends Action {
    type: InoActionType.SET_FETCHING;
    payload: InoSetFetchingPayload;
}

export interface InoSetAllPoolAction extends Action {
    type: InoActionType.SET_ALL_INO_POOL;
    payload: InoSetAllPoolPayload;
}

export type InoActions = InoSetFetchingAction | InoSetAllPoolAction;
