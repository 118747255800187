import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/nfts/collections/acknoledger';

const MintNFT: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/MintNFT/AcknoledgerINO')),
    name: 'AcknoledgerINO',
};

export default MintNFT;
