import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { ACTION_TYPES } from './MemberShip.action';

const initialState = {
    upComing: [],
};

const memberShipReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.GET_UPCOMING_PROJECT:
            return { ...state, upComing: action.payload };
            break;
        default:
            return state;
    }
};

const persistConfig = {
    key: 'memberShipReducer',
    storage,
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, memberShipReducer);
