import React from 'react';
import { injected, SUPPORTED_WALLETS } from 'src/configs/Connect/Configs.wallets';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';

function useActiveWallet() {
    const { connector } = useActiveWeb3React();
    return React.useMemo(() => {
        const { ethereum } = window;
        let isMetaMask = !!(ethereum && ethereum.isMetaMask);
        return Object.keys(SUPPORTED_WALLETS)
            .filter(
                (k) =>
                    SUPPORTED_WALLETS[k].connector === connector &&
                    (connector !== injected || isMetaMask === (k === 'METAMASK')),
            )
            .map((k) => SUPPORTED_WALLETS[k])[0];
    }, [connector]);
}

export default useActiveWallet;
