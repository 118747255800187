import styled, { ITheme } from 'styled-components';
import { FONTS } from '../../theme/Theme.fonts';

export const Wrapper = styled.div`
    background: ${({ theme }: { theme: ITheme }) => theme.dark2};
    .form {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .button {
        width: 100%;
    }

    .btn-signin {
        margin-top: 9px;
    }

    .btn-signup {
        margin-top: 56px;
    }

    .text-input {
        margin-top: 32px;
    }

    .title {
        margin: auto;
        font-style: normal;
    }
    .header-title {
        font-family: 'Orbitron';
        text-align: center;
        color: ${({ theme }: { theme: ITheme }) => theme.text3};
    }

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form legend {
        width: auto;
    }

    .forgot-text {
        color: ${({ theme }: { theme: ITheme }) => theme.text7};
        cursor: pointer;
        padding-top: 48px;
        text-align: center;
    }

    .signup-prefix-text {
        color: ${({ theme }: { theme: ITheme }) => theme.white};
        text-align: center;
        margin-top: 16px;
    }

    .signup-text {
        color: ${({ theme }: { theme: ITheme }) => theme.text3};
        cursor: pointer;
        text-align: center;
    }

    .wrap-term {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        margin: 32px auto 0;
        gap: 12px;
        .check-box {
            width: 20px;
            height: 20px;
            margin-top: 5px;
        }
    }

    .term-prefix {
        color: ${({ theme }: { theme: ITheme }) => theme.text4};
    }

    .term-suffix {
        color: ${({ theme }: { theme: ITheme }) => theme.text7};
        /* text-decoration: underline; */
    }

    .wrap-term-text {
        flex: 1;
        // padding-left: 12px;
    }

    .success-title {
        text-align: center;
        font-size: ${FONTS.SIZE.veryLarge}px;
        margin-bottom: 32px;
    }

    .success-desc {
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
        text-align: center;
        white-space: pre-line;
    }

    .btn-success {
        margin: 32px auto auto;
    }

    .error {
        color: ${({ theme }) => theme.red1};
        /* margin-bottom: -20px; */
        /* margin-top: 20px; */
        text-align: center;
    }

    .box-verify-route {
        max-width: 800px;
        height: 250px;
        border-radius: 8px;
        background-color: ${({ theme }: { theme: ITheme }) => theme.dark1};
        margin: 150px auto auto;
        position: relative;
        .box-image {
            position: absolute;
            top: 0;
            img {
                width: 40%;
            }
        }
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
                width: 80%;
                max-width: 800px;
                height: 250px;
                border-radius: 8px;
                margin: 150px auto auto;
                position: relative;

                .verify-title {
                    font-size: 32px;
                }
                .box-image {
                    position: absolute;
                    top: 130px;
                    img {
                        margin-left: 30%;
                        width: 40%;
                    }
                }
        `}
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
                width: 80%;
        `}
    }
    .verify-title {
        text-align: center;
        font-size: ${FONTS.SIZE.superLarge}px;
        padding-top: 32px;
        margin-bottom: 20px;
    }
    .loading-verify {
        text-align: center;
        font-size: ${FONTS.SIZE.large}px;
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
    }
    .verify-error {
        color: ${({ theme }) => theme.red1};
        text-align: center;
        font-size: ${FONTS.SIZE.large}px;
    }
    .warning-forgot-code {
        margin-top: 33px;
        font-size: ${FONTS.SIZE.regular}px;
        text-align: center;
        color: orange;
    }
`;

export const BoxImage = styled.div`
    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
    animation: shake 5s;
    animation-iteration-count: infinite;
`;
