import React, { memo, useEffect, useState } from 'react';
import { COMPONENT_IDS } from 'src/app/App.constant';
import { MenuOutlined } from '@ant-design/icons';
import withLogin from 'src/hoc/login.enhance';
import withConnect from 'src/hoc/withConnect';
import { ReactComponent as LogoSVG } from 'src/assets/svg/logo-infinite-launch.svg';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import { NavLink } from 'react-router-dom';

import { Button, Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { isLoginSelector } from 'src/modules/Auth/Auth.selector';
import { compose } from 'recompose';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { HeaderFrame } from './HeaderV2.styled';
import DropdownNetwork from './components/DropdownNetwork';
import MenuMobileV2 from './components/MenuMobileV2';
import ButtonConnectWallet from './components/ButtonConnectWallet';
import DropdownAccount from './components/DropdownAccount';
import ButtonPrimary from '../Button/Button.primary';
import DrawerMenu from './components/DrawerMenu';

interface IHeaderProps {
    openLoginModal: () => void;
    changeChainId: (chainId: number) => void;
}
function HeaderV2({ openLoginModal, changeChainId }: IHeaderProps & any) {
    const [visible, setVisible] = useState(false);
    const [scroll, setScroll] = useState(false);
    const isLogin = useSelector(isLoginSelector);
    const { account, chainId } = useActiveWeb3React();
    const wallet = useSelector((state: any) => state.wallet);

    let { pageYOffset } = window;
    window.addEventListener('scroll', () => {
        pageYOffset = window.pageYOffset;
        if (pageYOffset > 20) {
            if (!scroll) {
                setScroll(true);
            }
        } else {
            setScroll(false);
        }
    });
    const openMenu = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    useEffect(() => {
        if (account && wallet.chainId && chainId !== wallet.chainId) {
            changeChainId(wallet.chainId);
        }
    }, [wallet.chainId, account]);
    return (
        <>
            {/* <TopHeader /> */}
            <HeaderFrame
                id={COMPONENT_IDS.HEADER}
                style={{
                    top: '0',
                    background: scroll ? '#0A0909' : 'transparent',
                    transition: '1s',
                }}
            >
                <div className="menu-desktop">
                    <DrawerMenu />

                    {/* <div className="drawer-menu">
                        <div onClick={openMenuHeaderPC}>
                            <DrawerMenuSvg />
                            <span className="fs-regular fw-suppermedium">MENU</span>
                        </div>
                        <Drawer
                            placement="left"
                            width="40%"
                            closable
                            keyboard
                            visible={visibleMenu}
                            key="left"
                            onClose={onCloseMenuHeaderPC}
                            className="drawer-header-menu"
                            drawerStyle={{ backgroundColor: '#141416' }}
                            closeIcon={<IcClose style={{ marginTop: 8 }} width={14} height={14} />}
                        >
                        </Drawer>
                    </div> */}
                    <div className="logo">
                        <NavLink to="/">
                            <LogoSVG />
                        </NavLink>
                    </div>
                    <div className="right-header">
                        <div className="position-right-header">
                            <div className="network">
                                {!isLogin && (
                                    <ButtonPrimary
                                        title={isLogin ? 'Account' : 'Sign in'}
                                        className="btn-login"
                                        onClick={openLoginModal}
                                    />
                                )}
                                <DropdownNetwork />
                                <ButtonConnectWallet />
                                {isLogin && <DropdownAccount />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-mobile">
                    <div className="connect-mobile">
                        {/* <DropdownNetwork /> */}
                        <Button
                            className="btn-menu-mobile fs-regular fw-medium"
                            shape="round"
                            size="large"
                            style={{ color: 'white' }}
                            onClick={openMenu}
                        >
                            <MenuOutlined /> MENU
                        </Button>
                    </div>
                    <div className="logo">
                        <NavLink to="/">
                            <LogoSVG />
                        </NavLink>
                    </div>
                    <div className="mode-dark-light">mode</div>
                    <Drawer
                        placement="left"
                        width="100%"
                        closable
                        visible={visible}
                        key="left"
                        onClose={onClose}
                        className="drawer-header-mobile"
                        drawerStyle={{ backgroundColor: '#141416' }}
                        closeIcon={<IcClose style={{ marginTop: 8 }} width={20} height={20} />}
                    >
                        <MenuMobileV2 onClose={onClose} />
                    </Drawer>
                </div>
            </HeaderFrame>
        </>
    );
}
export default compose(withConnect, withLogin)(memo(HeaderV2));
