import React from 'react';
import { COMPONENT_IDS } from 'src/app/App.constant';
import { ReactComponent as LogoSVG } from 'src/assets/svg/logo-infinite-launch.svg';
import twitterImg from 'src/assets/image/twitter.png';
import mediumImg from 'src/assets/image/medium.png';
import telegramImg from 'src/assets/image/telegram.png';
import discordImg from 'src/assets/image/discord.png';
import CoingeckoImg from 'src/assets/image/footer/coingecko.png';
import CoinmarketcapImg from 'src/assets/image/footer/coinmarketcap.png';
import CryptorankcapImg from 'src/assets/image/footer/cryptorank.png';
import SolidProofImg from 'src/assets/image/footer/SolidProof.png';
import { ENVS } from 'src/configs/Configs.env';
import { Image } from 'antd';
import { FooterFrame } from './NewFooter.styled';

const social = [
    {
        image: twitterImg,
        link: ENVS.REACT_APP_TWITTER,
    },
    {
        image: mediumImg,
        link: ENVS.REACT_APP_MEDIUM,
    },
    {
        image: telegramImg,
        link: ENVS.REACT_APP_TELE_CHANNEL,
    },
    {
        image: discordImg,
        link: ENVS.REACT_APP_DISCORD,
    },
];
export default function NewFooter() {
    return (
        <FooterFrame id={COMPONENT_IDS.FOOTER}>
            <div className="line-break-top" />
            <div className="top-footer">
                <div className="left-logo">
                    <LogoSVG />
                </div>
                <div className="center-content">
                    Infinite Launch is a decentralized multi-chain fundraising platform enabling projects to raise
                    capital and promise safety to early stage investors. Stake Infinite Launch tokens to get
                    priority-access to promising projects.
                </div>
                <div className="right-socials">
                    {social.map((item) => {
                        return (
                            <a target="_blank" key={item.link} href={item.link} rel="noreferrer">
                                <Image preview={false} src={item.image} />
                            </a>
                        );
                    })}
                </div>
            </div>

            <div className="middle-footer">
                <div className="row-middle">
                    <ul className="general">
                        <li className="header-item-column">General</li>
                        <li className="content-item-column">
                            <a href="/accounts?tab=wallet">Lock Tier</a>
                        </li>
                        <li className="content-item-column">
                            <a href="/accounts?tab=wallet">Stake/Farm token</a>
                        </li>
                        <li className="content-item-column">
                            <a
                                target="_blank"
                                href="https://docs.google.com/forms/d/e/1FAIpQLSeXEdzOxJtfRhE_b8cWKn9p0DXinQzZ7TXi8VPVHuhYn0CA0Q/viewform"
                                rel="noreferrer"
                            >
                                Apply for IDO
                            </a>
                        </li>
                        <li className="content-item-column">
                            <a href="/InfiniteLaunch-PrivacyPolicy.pdf">Privacy Policy</a>
                        </li>
                        <li className="content-item-column">
                            <a href="/InfiniteLaunch-TermsOfService.pdf">Terms of use</a>
                        </li>
                        <li className="content-item-column">
                            <a href="/about-us">About us</a>
                        </li>
                    </ul>
                    <ul className="help">
                        <li className="header-item-column">Help</li>
                        <li className="content-item-column">
                            <a href="/how-to-get-guaranteed-allocation">How to participate in IDO</a>
                        </li>
                        <li className="content-item-column">
                            <a
                                target="_blank"
                                href="https://infinitelaunch.gitbook.io/infinite-launch/master/faq"
                                rel="noreferrer"
                            >
                                FAQ
                            </a>
                        </li>
                    </ul>
                    <ul className="communities">
                        <li className="header-item-column">Communities</li>
                        <li className="content-item-column">
                            <a href="https://t.me/Infinitelaunch_indonesia" target="_blank" rel="noreferrer">
                                🇮🇩 &nbsp; Indonesia
                            </a>
                        </li>
                        <li className="content-item-column">
                            <a href="https://t.me/InfiniteLaunchTR" target="_blank" rel="noreferrer">
                                🇹🇷 &nbsp; Turkey
                            </a>
                        </li>
                        <li className="content-item-column">
                            <a href="https://t.me/InfiniteLaunchChina" target="_blank" rel="noreferrer">
                                🇨🇳 &nbsp; China
                            </a>
                        </li>
                        <li className="content-item-column">
                            <a target="_blank" href="https://t.me/InfiniteLaunchVietnam" rel="noreferrer">
                                🇻🇳 &nbsp;VietNam
                            </a>
                        </li>
                        <li className="content-item-column">
                            <a href="https://t.me/InfiniteLaunchThailand" target="_blank" rel="noreferrer">
                                🇹🇭 &nbsp; Thailand
                            </a>
                        </li>
                    </ul>
                    <ul className="token">
                        <li className="header-item-column">Token</li>
                        <li className="content-item-column">
                            <a
                                href="https://www.coingecko.com/en/coins/infinite-launch"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={CoingeckoImg} preview={false} />
                                CoinGecko
                            </a>
                        </li>
                        <li className="content-item-column">
                            <a
                                href="https://coinmarketcap.com/currencies/infinite-launch"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={CoinmarketcapImg} preview={false} />
                                CoinMarketCap
                            </a>
                        </li>
                        <li className="content-item-column">
                            <a
                                href="https://cryptorank.io/fundraising-platforms/infinite-launch"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={CryptorankcapImg} preview={false} />
                                CryptoRank
                            </a>
                        </li>
                    </ul>
                    <ul className="audits">
                        <li className="header-item-column">Audits</li>
                        <li className="content-item-column">
                            <a
                                target="_blank"
                                href="https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_InfitniteLaunch.pdf "
                                rel="noreferrer"
                            >
                                <Image src={SolidProofImg} preview={false} />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="bottom-footer">
                    <div className="description">
                        Participants/Citizens from the following countries are strictly excluded/not allowed to
                        participate in the IDOs: Bolivia, Cambodia, Iran, Iraq, Libya, Nepal, Zimbabwe, Liberia,
                        Myanmar, North Korea.
                    </div>
                    <div className="copy-right">Copyright © Infinite Launch 2022</div>
                </div>
            </div>
        </FooterFrame>
    );
}
