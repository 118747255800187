import React from 'react';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { SUPPORTED_NETWORKS } from 'src/configs/Connect/Configs.networks';
import { useAptosWallet } from 'src/3rd/aptos';
import { getBalanceAptos } from 'src/3rd/aptos/helper';

function useActiveBalance() {
    const { account, library, chainId } = useActiveWeb3React();
    const { accountAptos } = useAptosWallet();
    const [balance, setBalance] = React.useState<any>('');
    const [symbol, setSymbol] = React.useState<string>('');
    const [isAptos, setIsAptos] = React.useState<boolean>(false);

    React.useEffect((): any => {
        if (!!account && !!library && !accountAptos?.address) {
            const _chainId: number = chainId as number;
            let stale = false;
            if (SUPPORTED_NETWORKS[_chainId || 0] && SUPPORTED_NETWORKS[chainId || 0].nativeCurrency) {
                setSymbol(SUPPORTED_NETWORKS[_chainId].nativeCurrency.symbol);
            }
            library
                .getBalance(account)
                .then((_balance: any) => {
                    if (!stale) {
                        setBalance(_balance);
                        setIsAptos(false);
                    }
                })
                .catch(() => {
                    if (!stale) {
                        setBalance('');
                    }
                });

            return () => {
                stale = true;
                setBalance('');
            };
        }
    }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds
    React.useEffect(() => {
        if (accountAptos?.address) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getBalanceAptos(accountAptos!.address!.toString()).then((value: any) => {
                setBalance(value);
                setIsAptos(true);
                setSymbol('APT');
            });
        }
    }, [accountAptos]);
    return {
        balance,
        symbol,
        isAptos,
    };
}

export default useActiveBalance;
