import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import BigNumber from 'bignumber.js';

let decimalSeparator = '.';
let groupSeparator = ',';

function getDecimalSeparator() {
    return decimalSeparator;
}

export function getGroupSeparator() {
    return groupSeparator;
}

const removeTrailingZeroes = (amountString: string) => {
    let formattedString = amountString;
    while (
        formattedString.length > 0 &&
        ((formattedString.includes(getDecimalSeparator()) && formattedString[formattedString.length - 1] === '0') ||
            formattedString[formattedString.length - 1] === getDecimalSeparator())
    ) {
        formattedString = formattedString.slice(0, formattedString.length - 1);
    }

    return formattedString;
};

const toFixed = ({ number, decimals = 4 }: { number: string | number; decimals: number }) => {
    const _number = Number(number);
    if (isNumber(_number) && !isNaN(_number)) {
        return removeTrailingZeroes(_number.toFixed(decimals).replace('.', getDecimalSeparator()));
    }
    return number;
};

const getDecimalsFromHumanAmount = (humanAmount: number, defaultDecimals: number) => {
    let decimals;
    if (humanAmount > 10) {
        decimals = 2;
    } else if (humanAmount > 1) {
        decimals = 3;
    } else if (humanAmount > 1e-1) {
        decimals = 4;
    } else if (humanAmount > 1e-2) {
        decimals = 5;
    } else {
        decimals = Math.max(defaultDecimals, 6);
    }
    return decimals;
};

const amount = (number: number, decimals?: number) => {
    try {
        const fmt = {
            decimalSeparator: getDecimalSeparator(),
            groupSeparator: getGroupSeparator(),
            groupSize: 3,
        };
        if (isNumber(number) && !isNaN(number)) {
            let _amount = new BigNumber(number).toNumber();
            const _decimals = getDecimalsFromHumanAmount(_amount, decimals || 0);
            return _amount
                ? removeTrailingZeroes(new BigNumber(_amount).toFormat(_decimals, BigNumber.ROUND_DOWN, fmt))
                : 0;
        }
        return number;
    } catch (e) {
        return number;
    }
};

export default {
    toFixed,
    amount,
};
