import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/membership';

const MemberShipRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/MemberShip')),
    name: 'MemberShip',
};

export default MemberShipRoute;
