import { BigNumber } from '@ethersproject/bignumber';
import { getAddress } from '@ethersproject/address';

/**
 * Returns true if the string value is zero in hex
 * @param hexNumberString
 */
export function isZero(hexNumberString: string): boolean {
    return /^0x0*$/.test(hexNumberString);
}

export const isEmptyValue = (text: string) =>
    BigNumber.isBigNumber(text)
        ? BigNumber.from(text).isZero()
        : text === '' || text.replace(/0/g, '').replace(/\./, '') === '';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
    try {
        return getAddress(value);
    } catch {
        return false;
    }
}
