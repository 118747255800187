import isEmpty from 'lodash/isEmpty';

const required = (value: any) => (isEmpty(value) ? 'Required' : undefined);

const maxLength = (max: number, message?: string) => (value: string) =>
    value && value.length > max ? message || `Must be ${max} characters or less` : undefined;

const minLength = ({ min, message, value }: { min: number; message?: string; value: string }) =>
    value && value.length < min ? message || `Must be ${min} characters or more` : undefined;

const email = ({ message, value }: { message?: string; value?: string }) =>
    required(value) ||
    (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
        ? message || 'Invalid email address'
        : undefined);

const password = ({ value }: { value?: string }) =>
    required(value) || minLength({ min: 6, value: value || '' }) || undefined;

const comparePass = ({ value, compareValue }: { compareValue?: string; value?: string }) =>
    value !== compareValue ? 'Invalid password' : undefined;

const validator = {
    minLength,
    maxLength,
    required,
    email,
    password,
    comparePass,
};

export default validator;
