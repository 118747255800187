import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/refund/realms-of-ethernity';

const Pool: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/Refund')),
    name: 'Refund - Realms Of Ethernity',
};

export default Pool;
