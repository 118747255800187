/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-autofocus */
import { Col, Row, Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CircleCloseIcon } from 'src/components/Icons';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import styled, { ITheme } from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }: { theme: ITheme }) => theme.dark1};
    > div {
        width: 100%;
    }
    .box-1 {
        width: 100%;
    }
    .btn-close {
        margin: auto 0 32px auto;
    }
    .header-title {
        margin: auto;
        font-style: normal;
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
    }
    .body-modal {
        padding: 24px;
        .box-balance {
            display: flex;
            flex: 1;
            margin-bottom: 16px;
            justify-content: flex-end;
            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #c4c4c4;
            }
        }
        .box-content {
            display: grid;
            padding: 8px 12px;
            width: 100%;
            border-radius: 12px;
        }
        .box-title-head {
            font-style: normal;
            font-weight: 100;
            font-size: 14px;
            color: #fcfcfd;
        }
        .box-title-sub {
            font-style: normal;
            font-size: 14px;
            color: #3772ff;
            margin-top: 20px;
            margin-right: 16px;
        }
        .btn:hover {
            transform: scale(1.03);
        }

        .box-title-sub1 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #fcfcfd;
            margin-top: 6px;
            cursor: pointer;
        }

        .box-button {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            .btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                height: 40px;
                background: #3772ff;
                border: none;
                font-style: normal;
                font-size: 14px;
                text-align: center;
                color: #fcfcfd;
                border-radius: 8px;
                width: 48%;
                font-weight: 600;
            }

            .btn-cancel {
                background: gray;
            }
        }
    }
    .header-modal {
        display: flex;
        align-items: flex-start;
        padding: 24px;
        height: 80px;
        background: #353945;
        border: 1px solid #353945;
        box-sizing: border-box;
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        border-radius: 12px 12px 0px 0px;
    }

    .cursor-pointer {
        cursor: pointer;
    }
    .cursor-pointer:hover {
        transform: scale(1.05);
    }
    .box-1-button {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        height: 32px;
        margin-top: 8px;
        button {
            font-style: normal;
            font-size: 12px;
            text-align: center;
            color: #fcfcfd;
        }
    }
    .active {
        background: #3772ff;
        border: 2px solid #3772ff;
    }
`;

const ModalUnlockTier = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    title = '',
    callBack,
}: {
    title: any;
    callBack: (closeModal: () => void) => Promise<any>;
}) => {
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(actionCloseModal());
    };

    const handleConfirm = () => {
        dispatch(actionCloseModal());
        callBack && callBack(handleClose);
    };

    return (
        <Wrapper>
            <Row className="header-modal">
                <Col className="hero-text" lg={17} xs={17}>
                    <p className="fw-bold fs-verylarge disable-pointer gradient-text header-title">Confirm</p>
                </Col>
                <Col className="hero-text" lg={7} xs={7}>
                    <CircleCloseIcon onClick={handleClose} className="btn-close" />
                </Col>
            </Row>
            <Row className="body-modal">
                <div className="box-content">
                    <Col className="hero-text" lg={24} xs={24}>
                        <p className="box-title-head">
                            Unlocking ILA earlier than 60 days will result in certain percentages of token burn. Please
                            refer to Tier Ranking Disclaimer in your account page for more information.
                        </p>
                    </Col>
                    <Col className="hero-text" lg={24} xs={24}>
                        <div className="box-title-sub">
                            <p>Do you wish to continue?</p>
                        </div>
                    </Col>
                </div>
                <div className="box-button">
                    <Button onClick={handleClose} className="btn btn-cancel">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} className="btn">
                        Confirm
                    </Button>
                </div>
            </Row>
        </Wrapper>
    );
};

export default ModalUnlockTier;
