import Row from 'src/components/Row';
import styled, { ITheme } from 'styled-components';

export const Wrapper = styled(Row)`
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 1536px;

    .group-watchlist {
        gap: 57px;
    }
    .ant-table-wrapper {
        width: 100%;
        margin-top: 40px;
        .ant-table-thead > tr > th {
            color: #777e91 !important;
            background: #23262f !important;
            padding: 10px 12px;
            border-bottom: 1px solid #353945;
            font-weight: 600;
        }
        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #24262f;
            transition: background 0.3s;
            background: #23262f;
            padding-top: 24px;
            padding-bottom: 24px;
        }
        .ant-table-tbody > tr {
            border-bottom: 1px solid #353945;
        }
        .ant-table-tbody > tr:hover {
            cursor: pointer;
            background: #14141650;
        }
        .ant-table-cell {
            color: #777e91 !important;
        }
        .ant-table-row {
            cursor: pointer;
        }
        .ant-table-row:hover {
            background: rgba(224, 248, 232, 1);
        }
        tbody > tr:hover {
            background: rgba(224, 248, 232, 1);
        }
        .ant-table-container {
            width: 100%;
            overflow-y: auto;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
    }
    .pool-details {
        margin: 0 auto !important;
        padding-top: 30px;
    }
    .nft-ranking-head {
        height: 100%;
        margin-left: 2px;
        cursor: pointer;
    }
    .info-circle {
        font-size: 16px;
    }
    .hero-content-subtext {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #777e90;
    }
    .rank-leader-board {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
        position: static;
        width: 123px;
        height: 24px;
        background: #3772ff;
        border-radius: 24px;
    }
    .leader-board-head-title {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        letter-spacing: -0.01em;
    }
    .row {
        display: flex;
        flex-direction: row;
    }

    .fluid {
        width: 100%;
        padding: 0 160px;
    }

    .list-project {
        margin-top: 60px;
        justify-content: center;
        margin: 60px auto 160px;
    }

    .title {
        color: ${({ theme }: { theme: ITheme }) => theme.text7};
    }

    .desc {
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
    }

    .summary ~ .summary {
        margin-left: 10px;
    }

    .summary {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: ${({ theme }: { theme: ITheme }) => theme.dark7};
        padding: 16px;
        border-radius: 16px;

        .summary-icon {
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid ${({ theme }: { theme: ITheme }) => theme.dark2};
            border-radius: 50px;
        }

        .summary-detail {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .text {
                color: ${({ theme }: { theme: ITheme }) => theme.text7};
            }

            .yellow {
                color: ${({ theme }: { theme: ITheme }) => theme.yellow1};
            }

            .light {
                color: ${({ theme }: { theme: ITheme }) => theme.blue3};
            }

            .dark {
                color: ${({ theme }: { theme: ITheme }) => theme.blue1};
            }
        }
    }

    .filter {
        margin-top: 20px;

        .filter-item {
            padding: 6px 12px;

            color: ${({ theme }: { theme: ITheme }) => theme.dark3};

            &.active {
                background: ${({ theme }: { theme: ITheme }) => theme.text7};
                color: ${({ theme }: { theme: ITheme }) => theme.black};

                border-radius: 50px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .hero-text {
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 32px;
            color: ${({ theme }: { theme: ITheme }) => theme.white};
            width: 100%;
            display: flex;
        }
    }
    .hero-content-subtext {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 34px;
        color: ${({ theme }: { theme: ITheme }) => theme.white};
    }
    .number-chart {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }: { theme: ITheme }) => theme.white};
    }
    .ant-table-wrapper .ant-table-thead > tr > th {
        padding: 10px 12px;
        border-bottom: 1px solid rgb(53, 57, 69);
        background: rgb(35, 38, 47) !important;
    }
    .team-cover {
        border-radius: 9999px;
    }
    .arrow-status {
        margin-left: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
    }
    .arrow-down {
        color: #f95976;
    }
    .arrow-up {
        color: #45b36b;
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
        border-bottom: 1px solid rgb(36, 38, 47);
        transition: background 0.3s ease 0s;
        background: rgb(35, 38, 47);
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .ant-table-wrapper .ant-table-container {
        width: 100%;
        overflow-y: auto;
    }
    .ant-table {
        background: unset;
    }
    .ant-table-wrapper .ant-table-cell {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff !important;
    }
    .ant-table-thead .ant-table-cell {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #e6e8ec !important;
    }
    .ant-table-column-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #e6e8ec;
    }
    .ant-table-wrapper .ant-table-tbody > tr:hover {
        cursor: pointer;
        background: rgb(36 38 47);
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: rgba(20, 20, 22, 0.314);
    }
    .ant-table-wrapper .ant-table-tbody > tr {
        border-bottom: 1px solid rgb(53, 57, 69);
    }
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid rgb(53, 57, 69);
        transition: background 0.3s;
    }
    td.ant-table-column-sort {
        background: rgba(20, 20, 22, 0.314);
    }
    .ant-table-tbody tr:nth-of-type(odd) > td {
        background-color: #252931;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff !important;
    }
    .ant-pagination {
        display: flex;
        -webkit-box-pack: end;
        justify-content: end;
        margin-bottom: 20px;
        margin-right: 5px;
    }
    .ant-pagination .ant-pagination-item {
        border: 1px solid rgb(119, 126, 145);
        background: rgb(35, 38, 48);
        border-radius: 6px;
    }
    .ant-pagination a {
        color: ${({ theme }: { theme: ITheme }) => theme.white};
    }
    .ant-pagination .ant-select-selector span {
        color: ${({ theme }: { theme: ITheme }) => theme.white};
    }
    .ant-select-selector,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid rgb(119, 126, 145);
        box-sizing: border-box;
        border-radius: 5px;
        background: rgb(35, 38, 48);
        cursor: pointer;
    }
    .ant-pagination .ant-pagination-item-link {
        border: 1px solid rgb(119, 126, 145);
        background: rgb(35, 38, 48);
        border-radius: 6px;
    }
    .chart-history {
        width: 100%;
        min-height: 610px;
        border-radius: 16px;
        // margin-top: 50px;
        .container {
            width: 100%;
            height: auto;
        }
    }
    .highcharts-container {
        width: 100%;
        height: auto;
    }
    .ant-pagination .ant-pagination-item-ellipsis {
        color: rgb(255, 255, 255);
    }
    .ant-pagination .ant-pagination-item-link svg {
        color: rgb(255, 255, 255);
    }
    .ant-pagination .ant-pagination-item-active {
        background: rgb(20, 20, 22) !important;
    }
    .items-center {
        justify-content: flex-start;
    }
    .title-collection {
        margin-left: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #e6e8ec;
    }
    .sub-title-collection {
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #b1b5c4;
    }

    .table-data {
        background: ${({ theme }: { theme: ITheme }) => theme.dark7};
        border-radius: 12px;
        width: 100%;
        margin-top: 30px;
        padding: 0 10px;
        // max-height: 900px;
        overflow-y: scroll;

        table {
            width: 100%;

            th {
                padding: 10px 12px;
                border-bottom: 1px solid ${({ theme }: { theme: ITheme }) => theme.dark2};
                color: ${({ theme }: { theme: ITheme }) => theme.dark3};
                font-weight: 600;
            }

            tr {
                border-bottom: 1px solid ${({ theme }: { theme: ITheme }) => theme.dark2};

                &:last-child {
                    border: none;
                }

                &:hover {
                    cursor: pointer;
                    background: ${({ theme }: { theme: ITheme }) => theme.dark8}50;
                }
            }

            td {
                padding: 24px 0;
                .project-logo {
                    width: 48px;
                    height: 48px;
                    border: none;
                    margin-right: 8px;
                }

                color: ${({ theme }: { theme: ITheme }) => theme.white};

                .align-center {
                    display: flex;
                    justify-content: center;
                }

                .token-logo {
                    width: 32px;
                }
            }
        }
    }

    .project {
        margin-top: 20px;

        & ~ .project {
            margin-left: 24px;
        }
    }

    .project-status {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: default;
        height: 32px;
        margin: 0;
        width: fit-content;
        min-width: 120px;

        background: ${({ theme }: { theme: ITheme }) => theme.blue1};
    }

    .project-item {
        display: flex;
        flex-direction: column;
        height: 100%;

        background: ${({ theme }: { theme: ITheme }) => theme.dark7};
        border-radius: 20px;
        overflow: hidden;
    }

    .project-item:first-child {
        margin-left: 0;
    }

    .project-item:last-child {
        margin-right: 0;
    }

    .project-cover {
        max-height: 235px;
        overflow: hidden;
    }

    .project-logo {
        margin-left: 0px;
        margin-top: 0%;
        border: 2px solid ${({ theme }: { theme: ITheme }) => theme.text6};
        border-radius: 50%;
        width: 72px;
        height: 72px;
        overflow: hidden;
    }

    .project-logo .ant-image-img {
        object-fit: cover;
        height: -webkit-fill-available;
    }

    .project-start {
        margin-top: -28px;
        padding-left: 10px;
        padding-right: 10px;
        max-height: 29px;
        background: ${({ theme }: { theme: ITheme }) => theme.dark2}60;
        backdrop-filter: blur(27.1828px);
        justify-content: space-between;
    }

    .project-start .ant-typography {
        color: ${({ theme }: { theme: ITheme }) => theme.text6};
    }

    .project-start-text {
        flex: 1;
        margin-left: 10px;
    }

    .project-name {
        color: ${({ theme }: { theme: ITheme }) => theme.text7};
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
    }

    .project-content {
        padding: 10px 20px 20px;
    }

    .project-details {
        justify-content: space-between;
        padding: 10px 0 0;
    }

    .project-text-label {
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
    }

    .project-text-value {
        color: ${({ theme }: { theme: ITheme }) => theme.text7};
    }

    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        .project ~ .project {
            margin-top: 24px;
            margin-left: 0px;
        }

        .summary ~ .summary {
            margin-left: 0;
            margin-top: 10px;
        }

        .fluid {
            padding: 24px;
        }

        .row {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 10px;
        }
        .ant-tabs-nav-wrap{
            justify-content: center;
        }
    `}
`;
