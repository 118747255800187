import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/nft-analytics/:address';

const Pool: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/NftTracking/NftTrackingDetail')),
    name: 'NFT Tracking Detail',
};

export default Pool;
