import { ACTION_TYPES } from './Collections.actions';
import { ICollectionState } from './Collections.type';

const initialState: ICollectionState = {
    all: {
        collections: [],
        total: 0,
    },
    owner: {
        collections: [],
        total: 0,
    },
    leaderBoard: {
        leaderBoard: [],
    },
    leaderBoardOwner: {
        leaderBoardOwner: [],
    },
    nfts: {
        total: 0,
    },
};

const reducer = (state = initialState, action: { payload: any; type: any }) => {
    const { payload, type } = action;
    switch (type) {
        case ACTION_TYPES.GET_ASSET_DETAILS: {
            return {
                ...state,
                owner: payload || [],
            };
        }
        case ACTION_TYPES.SET_COLLECTIONS: {
            return {
                ...state,
                all: payload,
            };
        }
        case ACTION_TYPES.GET_LEADER_BOARD: {
            return {
                ...state,
                leaderBoard: payload,
            };
        }
        case ACTION_TYPES.GET_LEADER_BOARD_OWNER: {
            return {
                ...state,
                leaderBoardOwner: payload,
            };
        }
        case ACTION_TYPES.GET_ACTIVITIES: {
            return {
                ...state,
                activities: payload,
            };
        }
        case ACTION_TYPES.GET_LIST_COLLECTIONS: {
            return {
                ...state,
                listCollections: payload,
            };
        }
        case ACTION_TYPES.TOTAL_NFTS: {
            return {
                ...state,
                nfts: payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
