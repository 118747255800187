import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/iframe/nft-ido/:id/item-list';

const IframeNft: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/IframeNftIdo/IframeNftItemList')),
    name: 'NFT Item List Ido',
};

export default IframeNft;
