import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { ACTION_TYPES } from './WhiteList.action';

const initialState = {
    WhiteListUserDetails: {},
    TelegramID: '',
    TwitterUsername: '',
    TwitterJoins: {},
    IsVerifiedEmail: false,
    WalletAddress: '',
};

const whiteListReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.GET_POOL_APPROVED_DETAIL:
            return {
                ...state,
                WhiteListUserDetails: {
                    ...action.payload,
                },
            };
            break;
        case ACTION_TYPES.UPDATE_TODO_LIST:
            return { ...state, ...action.payload };
            break;
        case ACTION_TYPES.LOGOUT:
            return {
                ...state,
                WhiteListUserDetails: initialState,
            };
            break;
        default:
            return state;
    }
};

const persistConfig = {
    key: 'whiteListReducer',
    storage,
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, whiteListReducer);
