import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as SupportIcSvg } from 'src/assets/svg/header/support-ic.svg';
import { ReactComponent as FundingIcSvg } from 'src/assets/svg/header/funding-ic.svg';
import { ReactComponent as StakeIcSvg } from 'src/assets/svg/header/stake-ic.svg';
import { ReactComponent as NftIcSvg } from 'src/assets/svg/header/nft-ic.svg';
import { ReactComponent as ContributeIcSvg } from 'src/assets/svg/header/contribute-ic.svg';
import { ReactComponent as AirdropSvg } from 'src/assets/svg/header/airdrop.svg';
import GuaranteedAllocationImg from 'src/assets/svg/header/guaranteedAllocation.png';
import { isLoginSelector } from 'src/modules/Auth/Auth.selector';
import { Drawer, Image } from 'antd';
import { ReactComponent as DrawerMenuSvg } from 'src/assets/svg/header/drawer.svg';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import withConnect from 'src/hoc/withConnect';
import withLogin from 'src/hoc/login.enhance';
import styled from 'styled-components';
import { DrawerMenuDesktop } from './DrawerMenu.styled';

interface IHeaderProps {
    openLoginModal: () => void;
    onLogout: () => void;
}

const WrapDrawer = styled(Drawer)`
    .ant-drawer-body {
        padding: 0 !important;
    }
`;
const CloseDrawerIcon = (props: any) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#close_drawer_header_icon)">
            <circle cx="16" cy="16" r="16" fill="#0A0909" />
            <path
                d="M17.4849 16.1875L21.9719 11.5803L22.8972 10.6302C23.0337 10.4901 23.0337 10.2623 22.8972 10.1221L21.9071 9.10557C21.7706 8.96541 21.5488 8.96541 21.4123 9.10557L16 14.6629L10.5877 9.10512C10.4512 8.96496 10.2294 8.96496 10.0929 9.10512L9.10237 10.1217C8.96588 10.2619 8.96588 10.4896 9.10237 10.6298L14.5151 16.1875L9.10237 21.7448C8.96588 21.8849 8.96588 22.1127 9.10237 22.2528L10.0924 23.2694C10.2289 23.4096 10.4507 23.4096 10.5872 23.2694L16 17.7121L20.487 22.3193L21.4123 23.2694C21.5488 23.4096 21.7706 23.4096 21.9071 23.2694L22.8972 22.2528C23.0337 22.1127 23.0337 21.8849 22.8972 21.7448L17.4849 16.1875Z"
                fill="#E7E6E6"
            />
        </g>
        <defs>
            <clipPath id="close_drawer_header_icon">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
const DrawerMenu = (props: IHeaderProps & any) => {
    const { openLoginModal, onLogout } = props;
    const [visibleMenu, setVisibleMenu] = React.useState(false);
    const isLogin = useSelector(isLoginSelector);

    // Menu Header PC
    const openMenuHeaderPC = () => {
        setVisibleMenu(true);
    };
    const onCloseMenuHeaderPC = () => {
        setVisibleMenu(false);
    };

    return (
        <div className="menu-desktop">
            <div className="drawer-menu">
                <div onMouseEnter={openMenuHeaderPC}>
                    <DrawerMenuSvg />
                    <span className="fs-regular fw-medium">MENU</span>
                </div>
                <WrapDrawer
                    placement="left"
                    width="40%"
                    closable
                    keyboard
                    visible={visibleMenu}
                    key="left"
                    onClose={onCloseMenuHeaderPC}
                    className="drawer-header-menu"
                    drawerStyle={{ backgroundColor: '#141416' }}
                    closeIcon={<CloseDrawerIcon style={{ marginTop: 2 }} />}
                >
                    <DrawerMenuDesktop onMouseLeave={onCloseMenuHeaderPC}>
                        <div className="dropdown-wrapper">
                            <div className="dropdown-top">
                                <div className="top-service">
                                    <p className="fs-medium">Services</p>
                                    <div className="service-wrapper">
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <FundingIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <div className="text fw-medium fs-regular">Fundraising</div>
                                                <NavLink to="/join-ido">
                                                    <div
                                                        className="hint fw-regular fs-small"
                                                        onClick={onCloseMenuHeaderPC}
                                                    >
                                                        Join IDO, IGO sales.
                                                    </div>
                                                </NavLink>
                                                <NavLink to="/ino">
                                                    <div
                                                        className="hint fw-regular fs-small"
                                                        onClick={onCloseMenuHeaderPC}
                                                    >
                                                        Join INO sales.
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <StakeIcSvg />
                                            </div>
                                            <div
                                                className="item-text"
                                                onClick={() => {
                                                    if (!isLogin) {
                                                        openLoginModal();
                                                    }
                                                }}
                                            >
                                                <NavLink to="/accounts?tab=wallet">
                                                    <div className="text fw-medium fs-regular">Stake & Earn</div>
                                                    <div className="hint fw-regular fs-small">
                                                        Buy ILA and lock to earn high APR.
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown">
                                            <div className="icon">
                                                <NftIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/marketplaces?status=all&sort=PriceAsc">
                                                    <div className="text fw-medium fs-regular">NFT Marketplace</div>
                                                    <div className="hint fw-regular fs-small">
                                                        Trading NFTs, pre-vested token and more.
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <NftIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/nft-analytics">
                                                    <div className="text fw-medium fs-regular">NFT Sniper tool</div>
                                                    <div className="hint fw-regular fs-small">
                                                        Never miss the latest NFT gems ever again.
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <ContributeIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/army">
                                                    <div className="text fw-medium fs-regular">
                                                        Become Core Contributors
                                                    </div>
                                                    <div className="hint fw-regular fs-small">Join ILA Army here.</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <AirdropSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/airdrop">
                                                    <div className="text fw-medium fs-regular">Airdrop Tool</div>
                                                    {/* <div className="hint fw-regular fs-small">Join ILA Army here.</div> */}
                                                </NavLink>
                                            </div>
                                        </div>
                                        {/* <div className="item-dropdown mt-item">
                                            <div className="icon">
                                                <SaveIcSvg />
                                            </div>
                                            <div className="item-text">
                                                <NavLink to="/stake-farming?type=farms">
                                                    <div className="text fw-medium fs-regular">Win by Saving</div>
                                                    <div className="hint fw-regular fs-small">
                                                        Start saving & win big prizes every week.
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="top-button">
                                    <a
                                        className="top-item"
                                        target="_blank"
                                        href="https://pancakeswap.finance/swap?outputCurrency=0x4fbedc7b946e489208ded562e8e5f2bc83b7de42"
                                        rel="noreferrer"
                                    >
                                        <div className="fs-medium">Buy ILA</div>
                                    </a>
                                    <NavLink className="top-item" to="/about-us">
                                        <div className="fs-medium">About us</div>
                                    </NavLink>
                                    <a
                                        className="top-item"
                                        target="_blank"
                                        href="https://blog.infinitelaunch.io/"
                                        rel="noreferrer"
                                    >
                                        <div className="fs-medium">Blog</div>
                                    </a>
                                    <NavLink className="top-item" to="/" onClick={onLogout}>
                                        <div className="fs-medium">Logout</div>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="dropdown-middle border-left-item">
                                <div className="box-upgrade-tier">
                                    <NavLink
                                        to="/accounts?tab=wallet"
                                        onClick={() => {
                                            if (!isLogin) {
                                                openLoginModal();
                                            }
                                        }}
                                    >
                                        <div className="left fs-small">Upgrade your tier rank</div>
                                    </NavLink>
                                    <div
                                        className="right fs-small"
                                        onClick={() => {
                                            window.open('https://t.me/InfiniteLaunch');
                                        }}
                                    >
                                        <SupportIcSvg />
                                        Support
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-bottom">
                                <div className="guaranteed-allocation">
                                    <Image
                                        className="guaranteed-allocation-img"
                                        preview={false}
                                        src={GuaranteedAllocationImg}
                                    />
                                    <div className="guaranteed-allocation-content">
                                        <div className="text-guaranteed fw-suppermedium fs-regular">
                                            Get Guaranteed Allocation
                                        </div>
                                        <div className="hint-guaranteed fw-regular fs-small">
                                            To access any IDO pool, you need ILA Tier or own an NFT.
                                        </div>
                                        <NavLink to="/how-to-get-guaranteed-allocation">
                                            <div className="how-it-work fs-small fw-regular">
                                                <span>See how it work</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DrawerMenuDesktop>
                </WrapDrawer>
            </div>
        </div>
    );
};

export default compose(withConnect, withLogin)(DrawerMenu);
