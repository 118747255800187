import React from 'react';
import { Col, Row, Image } from 'antd';
import { NavLink } from 'react-router-dom';
import GuaranteedAllocationImg from 'src/assets/svg/header/guaranteedAllocation.png';
import { ReactComponent as LogoSVG } from 'src/assets/svg/logo-infinite-launch.svg';
import { ReactComponent as SupportIcSvg } from 'src/assets/svg/header/support-ic.svg';
import { ReactComponent as FundingIcSvg } from 'src/assets/svg/header/funding-ic.svg';
import { ReactComponent as StakeIcSvg } from 'src/assets/svg/header/stake-ic.svg';
import { ReactComponent as NftIcSvg } from 'src/assets/svg/header/nft-ic.svg';
import { ReactComponent as ContributeIcSvg } from 'src/assets/svg/header/contribute-ic.svg';
import { ReactComponent as AirdropSvg } from 'src/assets/svg/header/airdrop.svg';
import { BTNPrimary } from 'src/components/Button';
import { useSelector } from 'react-redux';
import { isLoginSelector } from 'src/modules/Auth/Auth.selector';
import withLogin from 'src/hoc/login.enhance';

import { Wrapper } from './MenuMobileV2.styled';
import ButtonConnectWallet from './ButtonConnectWallet';

interface IHeaderProps {
    openLoginModal: () => void;
    onClose: () => void;
    onLogout: () => void;
}

const MenuMobileV2 = ({ onClose, openLoginModal, onLogout }: IHeaderProps & any) => {
    const isLogin = useSelector(isLoginSelector);
    return (
        <Wrapper>
            <NavLink className="logo" to="/" onClick={onClose}>
                <LogoSVG />
            </NavLink>
            <div className="tabs-menu-mobile">
                <div
                    className="item-menu-mobile fs-medium fw-suppermedium"
                    onClick={() => {
                        window.open(
                            'https://pancakeswap.finance/swap?outputCurrency=0x4fbedc7b946e489208ded562e8e5f2bc83b7de42',
                            '_blank',
                        );
                    }}
                >
                    Buy ILA
                </div>
                <NavLink to="/about-us" onClick={onClose}>
                    <div className="item-menu-mobile fs-medium fw-suppermedium">About Us</div>
                </NavLink>

                <div
                    className="item-menu-mobile fs-medium fw-suppermedium"
                    onClick={() => {
                        window.open('http://blog.infinitelaunch.io/', '_blank');
                    }}
                >
                    Blog
                </div>
                <div
                    className="item-menu-mobile fs-medium fw-suppermedium"
                    onClick={() => {
                        onLogout();
                        onClose();
                    }}
                >
                    Logout
                </div>
            </div>
            <div className="text-service fs-medium fw-suppermedium">Service</div>
            <div className="box-dropdown">
                <Row className="row-dropdown">
                    <Col xs={24} className="col-dropdown">
                        <div className="item-dropdown">
                            <div className="icon">
                                <FundingIcSvg />
                            </div>
                            <div className="item-text">
                                <div className="text fw-bold fs-small">Fundraising</div>
                                <NavLink to="/join-ido" onClick={onClose}>
                                    <div className="hint fw-regular fs-suppersmall">Join IDO, IGO sales</div>
                                </NavLink>
                                <NavLink to="/ino" onClick={onClose}>
                                    <div className="hint fw-regular fs-suppersmall">Join INO sales</div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="item-dropdown mt-item">
                            <div className="icon">
                                <StakeIcSvg />
                            </div>
                            <div
                                className="item-text"
                                onClick={() => {
                                    if (!isLogin) {
                                        openLoginModal();
                                    }
                                }}
                            >
                                <NavLink to="/accounts?tab=wallet" onClick={onClose}>
                                    <div className="text fw-bold fs-small">Stake & Earn</div>
                                    <div className="hint fw-regular fs-suppersmall">
                                        Buy ILA and lock to earn high APR
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="item-dropdown mt-item">
                            <div className="icon">
                                <NftIcSvg />
                            </div>
                            <div className="item-text">
                                <NavLink to="/marketplaces?status=all&sort=PriceAsc" onClick={onClose}>
                                    <div className="text fw-bold fs-small">NFT Marketplace</div>
                                    <div className="hint fw-regular fs-suppersmall">
                                        Trading NFTs, pre-vested token and more
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="item-dropdown mt-item">
                            <div className="icon">
                                <NftIcSvg />
                            </div>
                            <div className="item-text">
                                <NavLink to="/nft-analytics" onClick={onClose}>
                                    <div className="text fw-bold fs-small">NFT Sniper Tool</div>
                                    <div className="hint fw-regular fs-suppersmall">
                                        Never miss the latest NFT gems ever again
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="item-dropdown mt-item">
                            <div className="icon">
                                <ContributeIcSvg />
                            </div>
                            <div className="item-text">
                                <NavLink to="/army" onClick={onClose}>
                                    <div className="text fw-bold fs-small">Become Core Contributors</div>
                                    <div className="hint fw-regular fs-suppersmall">Join ILA Army here</div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="item-dropdown mt-item">
                            <div className="icon">
                                <AirdropSvg />
                            </div>
                            <div className="item-text">
                                <NavLink to="/airdrop" onClick={onClose}>
                                    <div className="text fw-bold fs-small">Airdrop Tool</div>
                                </NavLink>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} className="col-dropdown border-left-item">
                        <div className="item-dropdown mt-item">
                            <div className="box-upgrade-tier">
                                <NavLink
                                    to="/accounts?tab=wallet"
                                    onClick={() => {
                                        onClose();
                                        if (!isLogin) {
                                            openLoginModal();
                                        }
                                    }}
                                >
                                    <div className="left fs-suppersmall">Upgrade your tier rank</div>
                                </NavLink>

                                <div
                                    className="right fs-suppersmall"
                                    onClick={() => {
                                        window.open('https://t.me/InfiniteLaunch');
                                    }}
                                >
                                    <SupportIcSvg />
                                    Support
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} className="col-dropdown">
                        <div className="guaranteed-allocation">
                            <Image preview={false} src={GuaranteedAllocationImg} />
                            <div className="text-guaranteed fw-bold fs-small">Get Guaranteed Allocation</div>
                            <div className="hint-guaranteed fw-regular fs-small">
                                To access any IDO pool, you need ILA Tier or own an NFT.
                            </div>
                            <NavLink to="/how-to-get-guaranteed-allocation" onClick={onClose}>
                                <div className="how-it-work fs-small fw-regular">See how it work</div>
                            </NavLink>
                        </div>
                    </Col>
                </Row>
                <Row className="list-btn-account">
                    <ButtonConnectWallet />
                    {isLogin ? (
                        <NavLink
                            className="btn-primary fw-bold btn-login btn-account items-center"
                            onClick={onClose}
                            to="/accounts?tab=profile"
                        >
                            Account
                        </NavLink>
                    ) : (
                        <BTNPrimary
                            title={isLogin ? 'Account' : 'Login'}
                            className="btn-login"
                            onClick={openLoginModal}
                        />
                    )}
                </Row>
            </div>
        </Wrapper>
    );
};

export default withLogin(MenuMobileV2);
