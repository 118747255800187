import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/airdrop';

const AirdropRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('.')),
    name: 'Army',
};

export default AirdropRoute;
