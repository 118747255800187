import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Signin } from 'src/modules/Auth';
import { useHistory } from 'react-router-dom';
import { actionToggleModal } from '../components/Modal/Modal.actions';
import { actionLogout as logOutAuth } from '../modules/Auth/Auth.actions';
import { actionLogout as logOutWhiteList } from '../modules/WhiteList/redux/WhiteList.action';
import { actionLogout as logOutAccount } from '../modules/Accounts/redux/Account.action';

const withLogin = (WrappedComponent: FunctionComponent) => (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const openLoginModal = () => {
        dispatch(
            actionToggleModal({
                visible: true,
                data: <Signin />,
            }),
        );
    };
    const onLogout = () => {
        dispatch(logOutAuth());
        dispatch(logOutWhiteList());
        dispatch(logOutAccount());
        console.log('window.location.pathname', window.location.pathname.split('/')[1]);
        if (window.location.pathname.split('/')[1] === 'accounts') {
            history.push('/');
        }
    };
    return (
        <WrappedComponent
            {...{
                ...props,
                openLoginModal,
                onLogout,
            }}
        />
    );
};

export default withLogin;
