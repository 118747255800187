import http from 'src/services/http';
import Web3 from 'web3';
import { ENVS } from 'src/configs/Configs.env';
import { IListHistory } from 'src/modules/MintNFT/components/NFTDetails.type';
import orderBy from 'lodash/orderBy';
import { Web3Provider } from '@ethersproject/providers';
import { getContract } from 'src/functions/getContract';
import contractSkeleton from '../../../contracts/skeleton_nft.json';
import { camelCaseKeys } from '../../../utils/camelcase';

export const loadListNFTs = async (
    address: string,
    contractAddressCollection: string,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!account || !library) {
        throw new Error('Provider or Account not found');
    }
    const contractABI = contractSkeleton.abi;
    let listData: string[] = [];
    if (!contractAddressCollection) {
        return listData;
    }
    const contract = getContract(contractAddressCollection, contractABI, library, account);

    try {
        let accountAssets = await contract.balanceOf(address);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (accountAssets) {
            accountAssets = parseInt(accountAssets.toString(), 10);
            for (let index = 0; index < accountAssets; index += 1) {
                // eslint-disable-next-line no-await-in-loop
                let indexChar = await contract.tokenOfOwnerByIndex(address, index);
                listData.push(indexChar.toString());
            }
        }
        return listData;
    } catch (error) {
        console.log('error: ', error);
    }
};

export const loadListNFTsV2 = async (address: string) => {
    const contractABI: any = contractSkeleton.abi;
    const contractAddr: any = ENVS.REACT_APP_IS_MAINNET
        ? contractSkeleton.address.mainnet
        : contractSkeleton.address.testnet;

    const provideUrl = ENVS.REACT_APP_IS_MAINNET
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://data-seed-prebsc-1-s2.binance.org:8545/';
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);

    let listData: string[] = [];
    try {
        let accountAssets = await contract.methods.balanceOf(address).call();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        accountAssets = parseInt(accountAssets.toString(), 10);
        for (let index = 0; index < accountAssets; index += 1) {
            // eslint-disable-next-line no-await-in-loop
            let indexChar = await contract.methods.tokenOfOwnerByIndex(address, index).call();
            listData.push(indexChar.toString());
        }

        return listData;
    } catch (error) {
        console.log('error: ', error);
    }
};

export const apiGetAssetsDetail = async (id: string, collectionName: string, userID?: string) => {
    let urlGetAssetsDetails = `${ENVS.REACT_APP_BASE_API}assets/${collectionName}/${id}`;
    if (userID) {
        urlGetAssetsDetails += `?user_id=${userID}`;
    }
    return http.get(urlGetAssetsDetails, { headers: { Authorization: `Bearer ${window.token}` } });
};

export const apiGetAssetsDetails = async (ids: string[]) => {
    const params = ids.join(',');
    if (params) {
        const urlGetAssetsDetails = `${ENVS.REACT_APP_BASE_API}assets/?filter[nft_id.in]=${params}`;
        return http.get(urlGetAssetsDetails, { headers: { Authorization: `Bearer ${window.token}` } });
    }
    return [];
};

export const apiGetTradeVolume = async (collectionName: any) => {
    let urlTradeVolume = `${ENVS.REACT_APP_BASE_API}stast`;
    if (collectionName) {
        urlTradeVolume += `/${collectionName}`;
    }
    return http.get(urlTradeVolume);
};

export const apiGetCollections = async (page: number, limit: number, params: string[]) => {
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}assets/`;
    if (params.length > 0) {
        // urlGetAssets = `${urlGetAssets}?${params.join('&')}&filter[collection_seo_name]=bony-bastards`;
        urlGetAssets = `${urlGetAssets}?${params.join('&')}`;
    }
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
        params: { page, limit },
    });
};

export const apiGetNFTsFavorites = async (page: number, limit: number, params: string[]) => {
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}favorites/my-nft-favorites`;
    if (params.length > 0) {
        urlGetAssets = `${urlGetAssets}?${params.join('&')}`;
    }
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
        params: { page, limit },
    });
};

export const apiGetActivities = async (page: number, limit: number, params: string) => {
    let urlGetActivities = `${ENVS.REACT_APP_BASE_API}activity`;
    if (params) {
        urlGetActivities = `${urlGetActivities}?${params}`;
    }
    return http.get(urlGetActivities, {
        headers: { Authorization: `Bearer ${window.token}` },
        params: { page, limit },
    });
};

export const apiLeaderBoard = async (address?: string, collectionName?: string) => {
    console.log('collectionName', collectionName);
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}leaderboard`;
    if (collectionName) {
        urlGetAssets = `${ENVS.REACT_APP_BASE_API}leaderboard/${collectionName}`;
        if (address) {
            urlGetAssets = `${ENVS.REACT_APP_BASE_API}leaderboard/${collectionName}/${address}`;
        }
    }
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const apiGetListCollections = async (name?: string) => {
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}collections`;
    if (name) {
        urlGetAssets += `?filter[collection_seo_name]=${name}`;
    }
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const apiGetListCollectionBySeoName = async (seoName: string) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}collections/?filter[collection_seo_name]=${seoName}`;
    return http.get(apiURL, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const apiGetPoolApprovedByID = async (poolID: any, poolType?: number, poolStatus?: number) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}pool-approved/?filter[social_url.in]=${poolID}`;
    if (poolType) {
        apiURL += `&filter[pool_type]=${poolType}`;
    }
    if (poolStatus) {
        apiURL += `&filter[status]=${poolStatus}`;
    }
    const parameters = {
        method: 'GET',
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetInoBySlug = async (slug: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}ino?filter[slug]=${slug}`;
    const parameters = {
        method: 'GET',
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const apiGetInoByStatus = async (status: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}ino?filter[status]=${status}`;
    const parameters = {
        method: 'GET',
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetHistoryList = async (
    nftId: string,
    collectionId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type = 'list',
): Promise<IListHistory[]> => {
    // const urlHistory = `${ENVS.REACT_APP_BASE_API}activity/${type}?filter[nft_id]=${nftId}&filter[collection_id]=${collectionId}`;
    const urlHistory = `${ENVS.REACT_APP_BASE_API}activity/?filter[nft_id]=${nftId}&filter[collection_id]=${collectionId}`;
    const res = (await http.get(urlHistory)) as any;
    if (res.Items) {
        return orderBy(camelCaseKeys(res.Items || []), 'createdAt', 'desc');
    }
    return [];
};
export const apiUpdateFavorites = async (nftUid: any, favorite: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}favorites/add`;
    if (favorite) {
        apiURL = `${ENVS.REACT_APP_BASE_API}favorites/remove`;
    }
    const parameters = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
        body: JSON.stringify({ ID: nftUid }),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const apiGetCollectionsList = async (type: string) => {
    const filter: any = {
        game: 1,
        art: 2,
        sport: 3,
        music: 4,
    };
    let urlGetAssets = `${ENVS.REACT_APP_BASE_API}collections`;
    if (filter[type]) {
        urlGetAssets += `?filter[type] = ${filter[type]}`;
    }
    return http.get(urlGetAssets, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};

export const apiGetSettings = async (name: string) => {
    let url = `${ENVS.REACT_APP_BASE_API}settings/${name}?filter[status]=1`;
    return http.get(url, {
        headers: { Authorization: `Bearer ${window.token}` },
    });
};
