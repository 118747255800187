import React, { memo } from 'react';
import { Form } from 'antd';
import BTNPrimary from 'src/components/Button/Button.primary';
import { TextInput } from 'src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from 'src/modules/Auth/Auth.styled';
import { actionSetSignin } from 'src/modules/Auth/Auth.actions';
import { loadingSelector, signinSelector } from 'src/modules/Auth/Auth.selector';
import validator from 'src/components/ReduxForm/validator';
import withAuth from './Auth.enhance';

interface ISigninProps {
    openSignupModal: () => void;
    openForgotModal: () => void;
    onSignin: () => void;
}

const Signin = ({ openSignupModal, openForgotModal, onSignin }: ISigninProps & any) => {
    const dispatch = useDispatch();
    const { email, password, error } = useSelector(signinSelector);
    const loading = useSelector(loadingSelector);
    const [form] = Form.useForm();
    const formError = React.useMemo(() => {
        const _email = validator.email({ value: email });
        const _pass = validator.password({ value: password || '' });
        return {
            email: _email,
            pass: _pass,
            disabled: !!_email || !!_pass || loading,
        };
    }, [email, password, loading]);

    return (
        <Wrapper>
            <Form className="form" form={form} name="vertical">
                <div className="fw-bold disable-pointer fs title header-title">Sign in</div>
                <TextInput
                    value={email}
                    onUserInput={(text) => {
                        dispatch(actionSetSignin({ email: text }));
                    }}
                    fontSize="16px"
                    placeholder="Email"
                    className="text-input"
                    label="Email address"
                    type="email"
                    error={formError.email}
                />
                <TextInput
                    value={password}
                    onUserInput={(text) => {
                        dispatch(actionSetSignin({ password: text }));
                    }}
                    fontSize="16px"
                    placeholder="******"
                    className="text-input"
                    label="Password"
                    type="password"
                    error={formError.pass}
                />
                <div className="fs-small fw-regular forgot-text" onClick={openForgotModal}>
                    Forgot password
                </div>
                {!!error && <p className="fs-medium error">{error}</p>}
                <BTNPrimary
                    loading={loading}
                    disabled={formError.disabled}
                    className="button btn-signin"
                    title="Sign in"
                    onClick={onSignin}
                />
                <div className="fs-small fw-regular disable-pointer signup-prefix-text">
                    Don’t have an account?{' '}
                    <span className="signup-text fw-medium" onClick={openSignupModal}>
                        Create now
                    </span>
                </div>
            </Form>
        </Wrapper>
    );
};

export default withAuth(memo(Signin));
