import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/join-ido';

const MintNFT: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/Pool/ListPools')),
    name: 'ListPools',
};

export default MintNFT;
