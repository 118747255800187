import { AptosClient } from 'aptos';
import { isMainnet } from 'src/configs/Configs.env';

const NODE_URL = isMainnet ? 'https://fullnode.mainnet.aptoslabs.com/' : 'https://fullnode.devnet.aptoslabs.com';

const APTOS_COIN = '0x1::aptos_coin::AptosCoin';

const aptosClient = new AptosClient(NODE_URL);

export const getBalanceAptos = async (
    address: string,
    extraArgs?: {
        // The coin type to use, defaults to 0x1::aptos_coin::AptosCoin
        coinType?: string;
    },
): Promise<number> => {
    try {
        const coinType = extraArgs?.coinType ?? APTOS_COIN;
        const typeTag = `0x1::coin::CoinStore<${coinType}>`;
        const resources = await aptosClient.getAccountResources(address);
        const accountResource = resources.find((r) => r.type === typeTag);
        return (accountResource?.data as any).coin.value;
    } catch (error) {
        console.log('error: ', error);
        return 0;
    }
};

export default {
    NODE_URL,
    APTOS_COIN,
    aptosClient,
};
