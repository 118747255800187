/* eslint-disable no-await-in-loop */
import { ethers } from 'ethers';
import { actionCloseLoading, actionOpenLoading } from 'src/components/Loading/Loading.actions';
import { getILABalanceV2 } from 'src/modules/Accounts/redux/Account.service';
import {
    apiGetAssetsDetails,
    apiGetCollections,
    apiLeaderBoard,
    loadListNFTsV2,
    apiGetActivities,
    apiGetListCollections,
} from './Collections.service';

export const ACTION_TYPES = {
    GET_ASSET_DETAILS: 'GET_ASSET_DETAIL',
    SET_COLLECTIONS: 'SET_COLLECTIONS',
    TOTAL_NFTS: 'TOTAL_NFTS',
    GET_TRADE_VOLUME: 'GET_TRADE_VOLUME',
    GET_LEADER_BOARD: 'GET_LEADER_BOARD',
    GET_LEADER_BOARD_OWNER: 'GET_LEADER_BOARD_OWNER',
    GET_ACTIVITIES: 'GET_ACTIVITIES',
    GET_LIST_COLLECTIONS: 'GET_LIST_COLLECTIONS',
};

export const actionGetAssetDetails = (assetsIds: string[]) => async (dispatch: any) => {
    try {
        const assetDetails: any = await apiGetAssetsDetails(assetsIds);
        dispatch({
            type: ACTION_TYPES.GET_ASSET_DETAILS,
            payload: { collections: assetDetails.Items || [], total: assetDetails.Total },
        });
    } catch (error) {
        console.log('error: ', error);
    }
};

export const actionGetListCollections = (name?: any) => async (dispatch: any) => {
    try {
        const result: any = await apiGetListCollections(name);
        dispatch({
            type: ACTION_TYPES.GET_LIST_COLLECTIONS,
            payload: { data: result.Items || [] },
        });
    } catch (error) {
        console.log('error: ', error);
    }
};

export const actionGetActivities = (page: number, limit: number, filter?: any) => async (dispatch: any) => {
    try {
        const result: any = await apiGetActivities(page, limit, filter);
        dispatch({
            type: ACTION_TYPES.GET_ACTIVITIES,
            payload: { activities: result.Items || [], total: result.Total },
        });
    } catch (error) {
        console.log('error: ', error);
    }
};

export const actionGetLeaderBoard = (address?: any, collectionName?: any) => async (dispatch: any) => {
    try {
        const leaderBoard: any = await apiLeaderBoard(address, collectionName);
        let data = [];
        if (address) {
            const { lockInfo }: any = await getILABalanceV2(leaderBoard.Address);
            const listAssets = (await loadListNFTsV2(leaderBoard.Address)) as string[];
            data.push({
                Address: leaderBoard.Address,
                TotalVolume: leaderBoard.TotalVolume,
                CountTotalItem: listAssets.length,
                Tier: lockInfo[0] / 10 ** 18,
                Rank: leaderBoard.Rank,
            });
            dispatch({
                type: ACTION_TYPES.GET_LEADER_BOARD_OWNER,
                payload: { leaderBoardOwner: data || [] },
            });
        } else {
            for (let i = 0; i < leaderBoard.length; i += 1) {
                data.push({
                    Address: leaderBoard[i].Address,
                    TotalVolume: leaderBoard[i].TotalVolume,
                    CountTotalItem: null,
                    Tier: null,
                });
            }
            dispatch({
                type: ACTION_TYPES.GET_LEADER_BOARD,
                payload: { leaderBoard: data || [] },
            });
            let leaderBoardTemp = [...leaderBoard];
            const tasks = leaderBoard.map(async (item: any, index: any) => {
                const { lockInfo }: any = await getILABalanceV2(leaderBoard[index].Address);
                const listAssets = (await loadListNFTsV2(leaderBoard[index].Address)) as string[];
                leaderBoardTemp[index] = {
                    Address: leaderBoard[index].Address,
                    TotalVolume: leaderBoard[index].TotalVolume,
                    CountTotalItem: listAssets.length,
                    Tier: lockInfo[0] / 10 ** 18,
                };
                // dispatch({
                //     type: ACTION_TYPES.GET_LEADER_BOARD,
                //     payload: { leaderBoard: leaderBoardTemp || [] },
                // });
            });
            dispatch({
                type: ACTION_TYPES.GET_LEADER_BOARD,
                payload: { leaderBoard: leaderBoardTemp || [] },
            });
            await Promise.all(tasks);
        }
    } catch (error) {
        console.log('error: ', error);
    }
};

export const actionGetCollections =
    (
        page: number,
        limit: number,
        filter?: any,
        sort?: any,
        rarity?: any,
        minPrice?: any,
        maxPrice?: any,
        nftIdOwner?: any,
        statusFilter?: any,
        collectionFilter?: any,
        userId?: any,
        updateReducer?: boolean,
    ) =>
    async (dispatch: any) => {
        let minPriceFilter = minPrice;
        let maxPriceFilter = maxPrice;
        let min = 0;
        let max = ethers.utils.parseEther(parseInt('1000000000000', 10).toString()).toString();
        if (minPrice !== '') {
            minPriceFilter = ethers.utils.parseEther(minPrice.toString()).toString();
        }
        if (maxPrice !== '') {
            maxPriceFilter = ethers.utils.parseEther(maxPrice.toString()).toString();
        }
        if (updateReducer !== undefined && updateReducer) {
            dispatch(actionOpenLoading());
        }
        let nftOwners: string[] = [];
        if (nftIdOwner) {
            nftOwners = nftIdOwner.join(',');
        }
        try {
            let params: string[] = [];
            if (userId) {
                params = [...params, ...[`user_id=${userId}`]];
            }
            if (collectionFilter) {
                params = [...params, ...[`filter[collection_seo_name.in]=${collectionFilter}`]];
            }
            if (statusFilter === 'buy-now') {
                if (minPrice !== '' && maxPrice === '') {
                    params = [...params, ...[`filter[price.between]=${minPriceFilter},${max}`]];
                } else if (minPrice === '' && maxPrice !== '') {
                    params = [...params, ...[`filter[price.between]=${min},${maxPriceFilter}`]];
                } else {
                    params = [...params, ...[`filter[price.greaterthan]=0`]];
                }
                if (maxPrice !== '' && minPrice !== '') {
                    params = [...params, ...[`filter[price.between]=${minPriceFilter},${maxPriceFilter}`]];
                }
                if (nftOwners) {
                    params = [...params, ...[`filter[nft_id.notin]=${nftOwners}`]];
                }
            } else if (statusFilter === 'with_ids') {
                if (nftOwners) {
                    params = [...params, ...[`filter[nft_id.in]=${nftOwners}`]];
                }
            } else if (statusFilter === 'not-listed') {
                params = [...params, ...[`filter[price.lessthan]=1`]];
            } else if (statusFilter === 'owned') {
                params = [...params, ...[`filter[nft_id.in]=${nftOwners}`]];
                if (minPrice !== '' && maxPrice !== '') {
                    params = [...params, ...[`filter[price.between]=${minPriceFilter},${maxPriceFilter}`]];
                } else if (minPrice !== '' && maxPrice === '') {
                    params = [...params, ...[`filter[price.between]=${minPriceFilter},${max}`]];
                } else if (minPrice === '' && maxPrice !== '') {
                    params = [...params, ...[`filter[price.between]=${min},${maxPriceFilter}`]];
                }
            } else {
                params = [...params, ...[`filter=get_all`]];
                if (minPrice !== '' && maxPrice !== '') {
                    params = [...params, ...[`filter[price.between]=${minPriceFilter},${maxPriceFilter}`]];
                } else if (minPrice !== '' && maxPrice === '') {
                    params = [...params, ...[`filter[price.between]=${minPriceFilter},${max}`]];
                } else if (minPrice === '' && maxPrice !== '') {
                    params = [...params, ...[`filter[price.between]=${min},${maxPriceFilter}`]];
                }
            }

            if (rarity === 'Legendary') {
                params = [...params, ...[`filter[ranking.greaterthan]=0&filter[ranking.lessthan]=179`]];
            } else if (rarity === 'Epic') {
                params = [...params, ...[`filter[ranking.greaterthan]=178&filter[ranking.lessthan]=1334`]];
            } else if (rarity === 'Rare') {
                params = [...params, ...[`filter[ranking.greaterthan]=1333&filter[ranking.lessthan]=4444`]];
            } else if (rarity === 'Normal') {
                params = [...params, ...[`filter[ranking.greaterthan]=4443&filter[ranking.lessthan]=8888`]];
            }
            if (filter) {
                const keys = Object.keys(filter);
                for (const key of keys) {
                    const values = filter[key];
                    if (values && values.length > 0) {
                        let url = '';
                        for (const value of values) {
                            url = `${url}${value.replaceAll('_', ' ').toLowerCase()}${
                                value === values[values.length - 1] ? '' : ','
                            }`;
                        }
                        params = [...params, ...[`filter[${key.toLowerCase()}.in]=${url}`]];
                    }
                }
            }
            if (sort) {
                if (sort === 'PriceDesc') {
                    params = [...params, ...[`filter[price.sort]=desc`]];
                } else if (sort === 'NFTIDDesc') {
                    params = [...params, ...[`filter[nft_id.sort]=desc`]];
                } else if (sort === 'NFTIDAsc') {
                    params = [...params, ...[`filter[nft_id.sort]=asc`]];
                } else if (sort === 'RankingAsc') {
                    params = [...params, ...[`filter[ranking.sort]=desc`]];
                } else if (sort === 'RankingDesc') {
                    params = [...params, ...[`filter[ranking.sort]=asc`]];
                } else {
                    params = [...params, ...[`filter[price.sort]=asc`]];
                }
            }
            const response: any = await apiGetCollections(page, limit, params);
            const data = (response.Items || []).map((item: any) => {
                const ranking = item.Ranking;
                let apr = 0;
                if ((ranking >= 1 && ranking <= 178) || ranking === 'Legendary') {
                    apr = 0.2;
                }
                if ((ranking >= 179 && ranking <= 1333) || ranking === 'Epic') {
                    apr = 0.15;
                }
                if ((ranking >= 1334 && ranking <= 4443) || ranking === 'Rare') {
                    apr = 0.12;
                }
                if ((ranking >= 4444 && ranking <= 8887) || ranking === 'Normal') {
                    apr = 0.1;
                }
                return {
                    ...item,
                    APR: apr,
                };
            });

            if (updateReducer !== undefined && !updateReducer) return data;

            dispatch({
                type: ACTION_TYPES.SET_COLLECTIONS,
                payload: { collections: data || [], favorites: response.Favorites || [], total: response.Total },
            });
        } catch (error) {
            console.log('error: ', error);
        } finally {
            dispatch(actionCloseLoading());
        }
    };
