import { Button, Collapse } from 'antd';
import React from 'react';
import withSignInWallet from 'src/hoc/WalletConnect.enhanceSignIn';
import { Wrapper } from './SignWalletModal.styled';

const { Panel } = Collapse;
interface IProps {
    onSignInWallet: any;
}
function SignWalletModal({ onSignInWallet }: IProps & any) {
    return (
        <Wrapper>
            <div className="sign-btn">
                <Button
                    className="btn-sign-wallet"
                    onClick={() => {
                        onSignInWallet();
                    }}
                >
                    Sign in with Wallet
                </Button>
                <div className="title">to continue to your ILA account.</div>
            </div>
            <div className="collapse-modal-sign-wallet">
                <Collapse defaultActiveKey={['1']}>
                    <Panel showArrow={false} header="What happens when I sign in with my wallet?" key="1">
                        <p color="light">
                            <span>
                                In short, signing in with wallet is done by verifying that a signed message comes from a
                                specific wallet address.
                                <br />
                                <br />
                                You <strong>are not</strong> asked to pay or create a transaction when signing in, it is
                                simply a mechanism used by Web3 developers to authenticate users.
                            </span>
                        </p>
                    </Panel>
                </Collapse>
            </div>
        </Wrapper>
    );
}
export default withSignInWallet(SignWalletModal);
