import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/about-us';

const AboutRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/About')),
    name: 'About',
};

export default AboutRoute;
