import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import { Signin, Signup, Forgot } from './index';
import {
    actionForgotSubmitCode,
    actionForgotSubmitEmail,
    actionSetForgot,
    actionSetSignin,
    actionSetSignup,
    actionSetVerify,
    actionSignin,
    actionSignup,
    actionVerify,
} from './Auth.actions';
import AuthSuccess from './Auth.success';

const withAuth = (WrappedComponent: FunctionComponent) => (props: any) => {
    const dispatch = useDispatch();
    const [isStepSubmitCode, setIsStepSubmitCode] = React.useState(false);
    const openSigninModal = () => {
        dispatch(actionToggleModal({ visible: true, data: <Signin /> }));
    };
    const openSignupModal = () => {
        dispatch(
            actionToggleModal({
                visible: true,
                data: <Signup />,
            }),
        );
    };
    const openForgotModal = () => {
        dispatch(
            actionToggleModal({
                visible: true,
                data: <Forgot />,
            }),
        );
    };

    const onSignin = async () => {
        try {
            await dispatch(actionSignin());
            dispatch(
                actionToggleModal({
                    autoClose: true,
                    visible: true,
                    data: <AuthSuccess title="Login Success" desc="You are in." />,
                }),
            );
        } catch (e: any) {
            dispatch(actionSetSignin({ error: e.message }));
        }
    };
    const onSignup = async () => {
        try {
            await dispatch(actionSignup());
            const mess =
                'You has been signed up successfully.' +
                '\nCheck your email to verify your account.' +
                '\nIf you do not see the email in your inbox, try checking Promotional or Spam section.';
            dispatch(
                actionToggleModal({
                    visible: true,
                    autoClose: true,
                    data: <AuthSuccess title="Signup Success" desc={mess} />,
                }),
            );
        } catch (e: any) {
            dispatch(actionSetSignup({ error: e.message }));
        }
    };

    const openHomeScreen = () => {
        window.location.href = `${window.location.origin}/`;
    };

    const onVerifyEmail = async (token: string) => {
        try {
            const mess = `Thank you. Your email was verified. Please log in to us the service`;
            await dispatch(actionVerify(token));
            dispatch(
                actionToggleModal({
                    autoClose: true,
                    visible: true,
                    data: <AuthSuccess title="Verify Email" desc={mess} onSubmit={openHomeScreen} />,
                }),
            );
        } catch (e: any) {
            dispatch(actionSetVerify({ error: e.message }));
        }
    };

    const onForgotSubmitEmail = async () => {
        try {
            await dispatch(actionForgotSubmitEmail());
            setIsStepSubmitCode(true);
        } catch (e: any) {
            dispatch(actionSetForgot({ error: e.message }));
        }
    };

    const onForgotSubmitCode = async () => {
        try {
            const mess = `Password Reset Successfully. Please click Login to continue.`;
            await dispatch(actionForgotSubmitCode());
            dispatch(
                actionToggleModal({
                    autoClose: true,
                    visible: true,
                    data: <AuthSuccess title="Reset Success" desc={mess} onSubmit={openSigninModal} />,
                }),
            );
        } catch (e: any) {
            dispatch(actionSetForgot({ error: e.message }));
        }
    };

    return (
        <WrappedComponent
            {...{
                ...props,
                openSigninModal,
                openSignupModal,
                openForgotModal,

                onSignin,
                onSignup,
                isStepSubmitCode,
                onForgotSubmitEmail,
                onForgotSubmitCode,
                onVerifyEmail,
            }}
        />
    );
};

export default withAuth;
