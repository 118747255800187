import React from 'react';
import http from 'src/services/http';
import { ENVS } from 'src/configs/Configs.env';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import {
    useFarmContract,
    useILAContract,
    useSkeletonContract,
    useFarmContractNotConnectWallet,
} from 'src/hooks/useContract';
import { ethers } from 'ethers';
import {
    checkApprove,
    handleApprove,
    checkApproveTopUp,
    handleApproveTopUp,
    handleGetBalance,
    handleGetReward,
    handleGetStakingBalance,
    handleGetStakingFirstStakeTime,
    handleGetStakingNft,
    handleGetStakingNftApr,
    handleGetStakingPool,
    handleGetStakingTierAPY,
} from 'src/modules/StakeFarming/redux/StakeFarming.utils';
import { useDispatch } from 'react-redux';
import { isBSCNetwork } from '../../../functions/validate.network';
import { addToast } from '../../../components/Toast/redux/Toast.service';
import { TOAST_TYPES } from '../../../components/Toast/redux/Toast.action';

export interface IStateProps {
    isApprove: boolean;
    isApproveTopUp: boolean;
    earned: number;
    nftsStaked: string;
    balance: number;
    stakingAmount: number;
    loading: boolean;
    stakingPoolValue: number;
    stakingNfts: number[];
    stakingFirstTime: number;
    nftApr: number;
    tierAPY: number;
}

export interface IStakeProps {
    isConnect: boolean;
    isStake: boolean;
    state: IStateProps;
    onRefresh: () => void;
    handleCheckApprove: () => void;
    handleApprove: (approveAmount: number) => Promise<any>;
    handleCheckApproveTopUp: () => void;
    handleApproveTopUp: (approveAmount: number) => Promise<any>;
    handleGetUserData: () => Promise<any>;
    handleHarvest: () => Promise<any>;
    handleStakeMore: (amount: number) => Promise<any>;
    handleWithdraw: (amount: number) => Promise<any>;
    handleAddTopUp: (nftId: string) => Promise<any>;
    handleRemoveTopUp: (nftId: string) => Promise<any>;
}

export const useStake = (depositAddress: string, earnAddress: string, contractAddress: string): IStakeProps => {
    const [state, setState] = React.useState<IStateProps>({
        isApprove: false,
        isApproveTopUp: false,
        earned: 0,
        nftsStaked: '',
        balance: 0,
        stakingAmount: 0,
        loading: false,
        stakingPoolValue: 0,
        stakingNfts: [],
        stakingFirstTime: 0,
        nftApr: 0,
        tierAPY: 0,
    });
    const { account: userAddress, chainId } = useActiveWeb3React();
    const dispatch = useDispatch();
    let queryParams = window.location.search;
    const params = new URLSearchParams(queryParams);
    const isStake = (params.get('type') ? params.get('type') : 'farms') === 'pools';
    const poolContract: any = useFarmContract(contractAddress, true);
    const contractEarn: any = useFarmContract(earnAddress, true);
    const poolContractERC20: any = useILAContract(true, depositAddress);
    const skeletonContract = useSkeletonContract(true);
    const poolContractNotConnectWallet: any = useFarmContractNotConnectWallet(contractAddress);

    const checkNetwork = () => {
        const validNetwork = isBSCNetwork(chainId);
        if (!validNetwork) {
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Oops!',
                    description: 'Please switch to Binance Smart Chain network.',
                }),
            );
        }
        return validNetwork;
    };

    const getTotalLocked = async () => {
        const _stakingPool = await handleGetStakingPool(poolContractNotConnectWallet, contractAddress);
        setState((item) => ({
            ...item,
            stakingPoolValue: _stakingPool,
        }));
    };

    const onCheckApprove = async () => {
        if (!userAddress) return;
        const _isApprove = await checkApprove(poolContractERC20, userAddress, contractAddress);
        setState((item) => ({
            ...item,
            isApprove: _isApprove,
        }));
    };

    const onCheckApproveTopUp = async () => {
        if (!userAddress) return;
        const _isApproveTopUp = await checkApproveTopUp(skeletonContract, userAddress, contractAddress);
        setState((item) => ({
            ...item,
            isApproveTopUp: _isApproveTopUp,
        }));
    };

    const onGetUserData = async () => {
        try {
            if (!userAddress) return 0;
            setState((item) => ({
                ...item,
                loading: true,
            }));
            getTotalLocked().then();
            const [_earned, _balance, _stakingAmount, _stakingNfts, _stakingFirstTime, _nftApr, _tierAPY] =
                await Promise.all([
                    await handleGetReward(poolContract, userAddress),
                    await handleGetBalance(poolContractERC20, userAddress),
                    await handleGetStakingBalance(poolContract, userAddress),
                    await handleGetStakingNft(poolContract),
                    await handleGetStakingFirstStakeTime(poolContract, userAddress),
                    await handleGetStakingNftApr(poolContract, userAddress),
                    await handleGetStakingTierAPY(poolContract, userAddress),
                ]);
            setState((item) => ({
                ...item,
                earned: _earned,
                balance: _balance,
                stakingAmount: _stakingAmount,
                stakingNfts: _stakingNfts,
                stakingFirstTime: _stakingFirstTime,
                nftApr: _nftApr,
                loading: false,
                tierAPY: _tierAPY,
            }));
        } catch (e) {
            setState((item) => ({
                ...item,
                loading: false,
            }));
        }
    };

    const onInit = async () => {
        await Promise.all([await onCheckApprove(), await onCheckApproveTopUp(), await onGetUserData()]);
    };

    /** ACTIONS * */
    const onApprove = async (approveAmount: number) => {
        if (!userAddress || !checkNetwork()) return;
        return handleApprove(poolContractERC20, contractAddress, approveAmount);
    };

    const onApproveTopUp = async (approveAmount: number) => {
        if (!userAddress || !checkNetwork()) return;
        return handleApproveTopUp(skeletonContract, contractAddress, approveAmount);
    };

    const onHarvest = async () => {
        try {
            if (!userAddress || !checkNetwork()) return;
            const tx = await poolContract.claimReward();
            return tx;
        } catch (e) {
            throw e;
        }
    };

    const onStakeMore = async (amount: any) => {
        try {
            if (!userAddress || !checkNetwork()) return;
            const stakeAmount = ethers.utils.parseEther(amount.toString()).toString();
            const tx = await poolContract.stake(stakeAmount);
            return tx;
        } catch (e) {
            throw e;
        }
    };

    const onStakeNft = async (nftId: any) => {
        try {
            if (!userAddress || !checkNetwork()) return;
            const tx = await poolContract.stakeBonyNft(nftId);
            return tx;
        } catch (e) {
            throw e;
        }
    };

    const onRemoveTopUp = async (nftId: any) => {
        try {
            if (!userAddress || !checkNetwork()) return;
            const tx = await poolContract.unstakeBonyNft(nftId);
            return tx;
        } catch (e) {
            throw e;
        }
    };

    const onWithdraw = async (amount: any) => {
        try {
            if (!userAddress || !checkNetwork()) return;
            const stakeAmount = ethers.utils.parseEther(amount.toString()).toString();
            const tx = await poolContract.unstake(stakeAmount);
            return tx;
        } catch (e) {
            throw e;
        }
    };

    React.useEffect(() => {
        getTotalLocked().then();
        if (!userAddress || !depositAddress || !earnAddress) return;
        onInit().then();
    }, [poolContractERC20, userAddress, earnAddress, poolContractERC20, contractEarn, isStake, chainId]);

    return {
        isConnect: !!userAddress,
        isStake,
        state,
        onRefresh: onInit,
        handleCheckApprove: onCheckApprove,
        handleCheckApproveTopUp: onCheckApproveTopUp,
        handleGetUserData: onGetUserData,
        handleAddTopUp: onStakeNft,
        handleApprove: onApprove,
        handleApproveTopUp: onApproveTopUp,
        handleHarvest: onHarvest,
        handleStakeMore: onStakeMore,
        handleWithdraw: onWithdraw,
        handleRemoveTopUp: onRemoveTopUp,
    };
};
export const apiGetEarn = async (type?: any) => {
    let params = '';
    if (type) {
        params = `?filter[type]=${type}`;
    }
    return http.get(`earning/${params}`);
};
export const apiPriceToken = async (symbol: any) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}kucoin/orderbook/level1?symbol=${symbol}`;
    const parameters = {
        method: 'GET',
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
