import moment from 'moment';

const convertUTCToLocal = (time: string): string => {
    return moment.utc(parseInt(time, 10)).local().format('hh:mm A DD MMM, YYYY');
};

const convertDaysToTime = (days: number) => {
    return days * 60 * 60 * 1000;
};

export default {
    convertUTCToLocal,
    convertDaysToTime,
};
