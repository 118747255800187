import {
    apiGetGetAccessToken,
    apiGetNonceWithAddress,
    getSignature,
} from 'src/redux/walletAccount/WalletAccount.service';
import { IRootState } from 'src/redux/interface';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import { ACTION_TYPES, IToken } from './WalletAccount.type';

export const actionUpdateAccessToken = (payload: IToken) => ({
    type: ACTION_TYPES.ACTION_UPDATE_ACCESS_TOKEN,
    payload,
});

export const actionUpdateErrorCode = (statusCode: number) => ({
    type: ACTION_TYPES.ACTION_UPDATE_CODE_ERROR,
    payload: {
        statusCode,
    },
});
export const actionSignIn = (account: string) => async (dispatch: any, getState: () => IRootState) => {
    try {
        const state = getState();
        if (state.walletAccount.address.toUpperCase() === account.toUpperCase()) {
            return;
        }
        if (account) {
            const res = await apiGetNonceWithAddress(account);
            const { Address, Nonce } = res as any;
            const signature: string = await getSignature(Address, Nonce);
            const accessToken = await apiGetGetAccessToken(Address, signature);
            const newToken: IToken = {
                address: Address.toUpperCase(),
                token: accessToken,
                nonce: Nonce,
            };
            dispatch(actionUpdateAccessToken(newToken));
            dispatch(actionCloseModal());
        } else {
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'OOPS!',
                    description: 'Please connect your wallet',
                }),
            );
        }
    } catch (error: any) {
        console.log('actionSignIn: error', error);
    }
};
