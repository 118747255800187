import { BscConnector } from '@binance-chain/bsc-connector';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { injected } from 'src/configs/Connect/Configs.wallets';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import RPC from 'src/configs/Connect/Configs.rpc';

export interface IWallet {
    name: string;
    icon: string;
    connector?: any;
    href?: any;
    network?: string;
}
export interface INetWork {
    name: string;
    icon: string;
    iconDisabled: string;
    chainNumb?: any;
    wallets: IWallet[];
}
export const networkList: INetWork[] = [
    {
        name: 'Ethereum',
        icon: 'ethereum.svg',
        iconDisabled: 'ethereum-disable.svg',
        chainNumb: ChainId.MAINNET,
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MAINNET],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Binance',
        icon: 'bsc.svg',
        chainNumb: ChainId.BSC,
        iconDisabled: 'bsc-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new BscConnector({ supportedChainIds: [56] }),
                name: 'Binance',
                icon: 'binance.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.BSC],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Polygon',
        icon: 'polygon.svg',
        chainNumb: ChainId.MATIC,
        iconDisabled: 'polygon-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MATIC],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Cronos',
        icon: 'cronos.svg',
        chainNumb: ChainId.CRO,
        iconDisabled: 'cronos-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MATIC],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Base',
        icon: 'base.svg',
        chainNumb: ChainId.BASE,
        iconDisabled: 'base-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
        ],
    },
    {
        name: 'Arbitrum',
        icon: 'arbitrum.svg',
        chainNumb: ChainId.ARBITRUM,
        iconDisabled: 'arbitrum-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MATIC],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Fantom',
        icon: 'fantom.svg',
        chainNumb: ChainId.FANTOM,
        iconDisabled: 'fantom-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.FANTOM],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Avalanche',
        icon: 'avax.svg',
        chainNumb: ChainId.AVALANCHE,
        iconDisabled: 'avax-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.AVALANCHE],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Solana',
        icon: 'solana.svg',
        iconDisabled: 'solana-disable.svg',
        chainNumb: 'solana',
        wallets: [
            {
                name: 'Phantom',
                icon: 'phantom.svg',
            },
        ],
    },
    {
        name: 'Aptos',
        icon: 'aptos.svg',
        iconDisabled: 'aptos-disable.svg',
        chainNumb: 'aptos',
        wallets: [
            {
                network: 'aptos',
                name: 'Petra',
                icon: 'petra.jpeg',
            },
            {
                network: 'aptos',
                name: 'Martian',
                icon: 'martian.jpeg',
            },
            {
                network: 'aptos',
                name: 'Pontem',
                icon: 'pontem.jpeg',
            },
        ],
    },
    {
        name: 'SUI',
        icon: 'sui.png',
        iconDisabled: 'sui-disable.png',
        chainNumb: 'sui',
        wallets: [],
    },
    {
        name: 'Base Testnet',
        icon: 'base.svg',
        chainNumb: ChainId.BASE_GOERLI_TESTNET,
        iconDisabled: 'base-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
        ],
    },
    {
        name: 'Binance Testnet',
        icon: 'bsc.svg',
        chainNumb: ChainId.BSC_TESTNET,
        iconDisabled: 'bsc-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
        ],
    },
    {
        name: 'Polygon Testnet',
        icon: 'polygon.svg',
        chainNumb: ChainId.MATIC_TESTNET,
        iconDisabled: 'polygon-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
        ],
    },
    {
        name: 'Fantom Testnet',
        icon: 'fantom.svg',
        chainNumb: ChainId.FANTOM_TESTNET,
        iconDisabled: 'fantom-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
        ],
    },
    {
        name: 'Cronos Testnet',
        icon: 'cronos.svg',
        chainNumb: ChainId.CRO_TESTNET,
        iconDisabled: 'cronos-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        25, // cronos
                        338, // cronos testnet
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MATIC],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Avalanche Testnet',
        icon: 'avax.svg',
        chainNumb: ChainId.AVALANCHE_TESTNET,
        iconDisabled: 'avax-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'MetaMask',
                icon: 'metamask.svg',
            },
        ],
    },
];
