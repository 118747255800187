import React, { useCallback } from 'react';
import styled, { ITheme } from 'styled-components/macro';
import { EyeIcon } from 'src/components/Icons';

const WrapInput = styled.div`
    position: relative;
    .icon {
        position: absolute;
        right: 15px;
        top: 43px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Input = styled.input<{ error?: boolean; fontSize?: string }>`
    font-size: ${({ fontSize }) => fontSize || '1.25rem'};
    outline: none;
    flex: 1 1 auto;
    background-color: ${({ theme }) => theme.bg1};
    transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
    color: ${({ theme }) => theme.white};
    border-color: ${({ error, theme }) => (error ? theme.red1 : theme.white)};
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: 2px solid ${({ error, theme }) => (error ? theme.red1 : theme.text4)};
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-appearance: textfield;

    ::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    ::placeholder {
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
    }

    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        border: 1px solid #6A6C7B;
    `}
`;

const Label = styled.p`
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }: { theme: ITheme }) => theme.dark4};
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        color: #F5F5F5;
    `}
`;

const ErrorText = styled.p`
    font-size: 12px;
    font-weight: 400;
    margin-top: 6px;
    color: ${({ theme }: { theme: ITheme }) => theme.red1};
`;

const TextInput = ({
    className,
    value,
    onUserInput,
    placeholder,
    fontSize,
    label,
    type = 'text',
    error = '',
}: {
    className?: string;
    value?: string | undefined;
    onUserInput: (value: string) => void;
    placeholder: string;
    fontSize: string;
    label: string;
    type?: string;
    error?: string;
}) => {
    const [typeState, setTypeState] = React.useState<string>(type);
    const handleInput = useCallback(
        (event) => {
            onUserInput(event.target.value);
        },
        [onUserInput],
    );

    const showError = !!error && value !== undefined;

    return (
        <WrapInput className={className}>
            <Label style={{ fontSize: 14 }}>{label}</Label>
            <Input
                type={typeState}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                placeholder={placeholder || ''}
                onChange={handleInput}
                value={value || ''}
                fontSize={fontSize}
                error={!!error && value !== undefined}
            />
            {type === 'password' && (
                <div className="icon">
                    <EyeIcon
                        show={!!typeState && typeState === 'password'}
                        onClick={() => {
                            setTypeState(typeState === 'password' ? 'text' : 'password');
                        }}
                    />
                </div>
            )}
            {showError && <ErrorText>{error}</ErrorText>}
        </WrapInput>
    );
};

export default TextInput;
