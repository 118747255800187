import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { ACTION_TYPES } from './StakeFarming.action';

const initialState = {
    earning: [],
};

const stakeFarmingReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.GET_EARN:
            return { ...state, earning: action.payload };
            break;
        case ACTION_TYPES.GET_PRICE_ILA:
            return { ...state, ILAPrice: action.payload };
            break;
        default:
            return state;
    }
};

const persistConfig = {
    key: 'stakeFarmingReducer',
    storage,
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, stakeFarmingReducer);
