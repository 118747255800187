import { Reducer } from 'redux';
import { InoActions, InoActionType, InoState } from 'src/modules/MintNFT/redux/Ino.types';

const initialState: InoState = {
    isFetching: false,
    upComingInos: [],
    currentInos: [],
    fundedInos: [],
};

const inoReducer: Reducer<InoState, InoActions> = (state = initialState, action: InoActions): InoState => {
    switch (action.type) {
        case InoActionType.SET_FETCHING: {
            const { isFetching } = action.payload;
            return {
                ...state,
                isFetching,
            };
        }
        case InoActionType.SET_ALL_INO_POOL: {
            const { fundedInos, currentInos, upComingInos } = action.payload;
            return {
                ...state,
                fundedInos,
                currentInos,
                upComingInos,
            };
        }
        default:
            return state;
    }
};

export default inoReducer;
