import styled, { ITheme } from 'styled-components';

const PrimaryText = styled.p`
    letter-spacing: 0.02em;
    font-family: 'Orbitron';
    font-style: normal;
    color: ${({ theme }: { theme: ITheme }) => theme.text3};
`;

export default PrimaryText;
