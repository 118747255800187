import React, { memo } from 'react';
import { Form } from 'antd';
import BTNPrimary from 'src/components/Button/Button.primary';
import { TextInput } from 'src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from 'src/modules/Auth/Auth.styled';
import { actionSetForgot } from 'src/modules/Auth/Auth.actions';
import { forgotSelector, loadingSelector } from 'src/modules/Auth/Auth.selector';
import validator from 'src/components/ReduxForm/validator';
import PrimaryText from 'src/components/PrimaryText';
import withAuth from './Auth.enhance';

interface ISigninProps {
    openSignupModal: () => void;
    isStepSubmitCode: boolean;
    onForgotSubmitEmail: () => void;
    onForgotSubmitCode: () => void;
}

const Forgot = ({ openSignupModal, isStepSubmitCode, onForgotSubmitEmail, onForgotSubmitCode }: ISigninProps & any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { email, code, pass, confirmPass, error } = useSelector(forgotSelector);
    const loading = useSelector(loadingSelector);
    const formError = React.useMemo(() => {
        const _email = validator.email({ value: email });
        const _code = validator.required(code);
        const _pass =
            validator.password({ value: pass }) || validator.comparePass({ value: pass, compareValue: confirmPass });
        const _confirm =
            validator.password({ value: confirmPass }) ||
            validator.comparePass({ value: confirmPass, compareValue: pass });
        return {
            email: _email,
            code: _code,
            pass: _pass,
            confirm: _confirm,
            disabledEmail: !!_email || loading,
            disabledCode: !!_code || !!_pass || !!_confirm || loading,
        };
    }, [email, code, pass, confirmPass, loading]);
    const renderSubmitEmail = () => (
        <>
            <TextInput
                value={email}
                onUserInput={(text) => {
                    dispatch(actionSetForgot({ email: text || '' }));
                }}
                fontSize="12"
                placeholder="Email address"
                className="text-input"
                label="EMAIL"
                type="email"
                error={formError.email}
            />
            {!!error && <p className="fs-medium error">{error}</p>}
            <BTNPrimary
                disabled={formError.disabledEmail}
                loading={loading}
                className="button btn-signin"
                title="Continue"
                onClick={onForgotSubmitEmail}
            />
        </>
    );
    const renderSubmitCode = () => (
        <>
            <p className="warning-forgot-code">Please check your email to get code.</p>
            <TextInput
                value={code}
                onUserInput={(text) => {
                    dispatch(actionSetForgot({ code: text || '' }));
                }}
                fontSize="12"
                placeholder="Confirm Code"
                className="text-input"
                label="CONFIRM CODE"
                type="text"
                error={formError.code}
            />
            <TextInput
                value={pass}
                onUserInput={(text) => {
                    dispatch(actionSetForgot({ pass: text || '' }));
                }}
                fontSize="12"
                placeholder="New password"
                className="text-input"
                label="NEW PASSWORD"
                type="password"
                error={formError.pass}
            />
            <TextInput
                value={confirmPass}
                onUserInput={(text) => {
                    dispatch(actionSetForgot({ confirmPass: text || '' }));
                }}
                fontSize="12"
                placeholder="Confirm Password"
                className="text-input"
                label="CONFIRM PASSWORD"
                type="password"
                error={formError.confirm}
            />
            {!!error && <p className="fs-medium error">{error}</p>}
            <BTNPrimary
                disabled={formError.disabledCode}
                loading={loading}
                className="button btn-signin"
                title="Continue"
                onClick={onForgotSubmitCode}
            />
        </>
    );

    return (
        <Wrapper>
            <Form className="form" form={form} name="vertical">
                <PrimaryText className="fw-bold disable-pointer title header-title">Forgot password</PrimaryText>
                {isStepSubmitCode ? renderSubmitCode() : renderSubmitEmail()}
                <div className="fs-suppersmal fw-suppermedium disable-pointer signup-prefix-text">
                    Don’t have an account?{' '}
                    <span className="signup-text" onClick={openSignupModal}>
                        Signup
                    </span>
                </div>
            </Form>
        </Wrapper>
    );
};

export default withAuth(memo(Forgot));
