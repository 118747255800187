/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Col, Tooltip, Image, Pagination, Layout, Row } from 'antd';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import { ReactComponent as EpicSVG } from 'src/assets/image/epic.svg';
import { ReactComponent as LegendarySVG } from 'src/assets/image/legendary.svg';
import { ReactComponent as NormalSVG } from 'src/assets/image/normal.svg';
import { ReactComponent as RareSVG } from 'src/assets/image/rare.svg';
import { BTNPrimary } from 'src/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { apiUpdateFavorites, apiGetNFTsFavorites } from 'src/modules/Collections/redux/Collections.service';
import { actionCloseLoading, actionOpenLoading } from 'src/components/Loading/Loading.actions';
import { Wrapper } from 'src/modules/Accounts/Favorited.styled';

const { Content } = Layout;
const Styled = styled.div`
    .ant-tooltip-inner {
        text-align: center;
        background-color: rgb(20 20 22);
        border-radius: 5px;
        width: 100px;
    }
`;
const LIMIT = 20;
const MyWallet = React.memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [_pageSize, setPageSize] = React.useState(LIMIT);
    const [_page, setPage] = React.useState(1);

    const { userInfo } = useSelector((state: any) => {
        return state.account;
    });

    const [listNfts, setListNfts] = React.useState<any>([]);
    const [totalListNfts, setTotalListNfts] = React.useState<any>(0);

    const onGetData = async (page: any, pageSize: any) => {
        pageSize && setPageSize(pageSize);
        setPage(page);
        dispatch(actionOpenLoading());

        let params: string[] = [];
        if (userInfo?.ID) {
            params = [...params, ...[`user_id=${userInfo?.ID}`]];
        }
        const listNftsResult: any = await apiGetNFTsFavorites(page, pageSize, params);
        setListNfts(listNftsResult?.Items);
        setTotalListNfts(listNftsResult.Total);
        setIsLoading(false);
        dispatch(actionCloseLoading());
    };

    const onChangePage = (page: number, pageSize?: number) => {
        onGetData(page, pageSize).then();
    };

    useEffect(() => {
        onGetData(1, LIMIT).then();
    }, []);

    const onClickToAllNft = async () => {
        return history.push(`/nfts/marketplaces?status=all`);
    };

    const getIconRanking = (ranking: any) => {
        if ((ranking >= 1 && ranking <= 178) || ranking === 'Legendary') {
            return <LegendarySVG className="icon-ranking-list" />;
        }
        if ((ranking >= 179 && ranking <= 1333) || ranking === 'Epic') {
            return <EpicSVG className="icon-ranking-list" />;
        }
        if ((ranking >= 1334 && ranking <= 4443) || ranking === 'Rare') {
            return <RareSVG className="icon-ranking-list" />;
        }
        if ((ranking >= 4444 && ranking <= 8887) || ranking === 'Normal') {
            return <NormalSVG className="icon-ranking-list" />;
        }
    };
    const onChangeFavorite = async (e: any, favorite: any, nftID: any, type: any) => {
        const newList = listNfts.map((item: any) => {
            if (item.ID === nftID) {
                const updatedItem = {
                    ...item,
                    IsFavorited: !favorite,
                    Favorites: type === 'Unfavorite' ? item.Favorites - 1 : item.Favorites + 1,
                };

                return updatedItem;
            }

            return item;
        });
        setListNfts(newList);
        await apiUpdateFavorites(nftID, favorite);
    };
    return (
        <Wrapper>
            <Row className="row-content">
                <Col className="wallet">
                    <span className="fs-26 fw-bold favorite-text">Your favorite NFTs</span>
                    <BTNPrimary
                        className="btn-connect-wallet"
                        title="Click to view all NFTs"
                        onClick={() => onClickToAllNft()}
                    />
                </Col>
            </Row>
            <Row className="row-content">
                <Content>
                    <div className="content">
                        <div className="list-item" style={{ flex: 1 }}>
                            {totalListNfts > 0 ? (
                                <div className="top-list-filter">
                                    <p className="total-result-filter bottom-filter">
                                        Showing {_page === 1 ? _page : _pageSize * _page - _pageSize + 1} to{' '}
                                        {_pageSize * _page > totalListNfts ? totalListNfts : _pageSize * _page} of{' '}
                                        {totalListNfts} results
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}{' '}
                            <Row>
                                {listNfts.length === 0 && !isLoading && (
                                    <Col lg={24}>
                                        <Row style={{ width: '100%' }}>
                                            <Col lg={24} xs={24} className="fw-bold fs-26 favorite-text">
                                                You haven't favorited any items yet
                                            </Col>
                                            <Col lg={24} xs={24} className="have-not-favorite">
                                                <BTNPrimary
                                                    className="btn-primary fs-small fw-medium back-to-all"
                                                    title="Back to all Items"
                                                    onClick={() => onClickToAllNft()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                                {listNfts.map((item: any) => {
                                    const price = new BigNumber(item.Price.toString()).div(10 ** 18).toString();
                                    const iconImage = getIconRanking(item?.Ranking);
                                    return (
                                        <Col key={item.NFTID} className="col-item-nfts" xl={5} lg={12} md={12} xs={24}>
                                            <div className="item">
                                                <Link to={`/nfts/marketplaces/${item.CollectionSeoName}/${item.NFTID}`}>
                                                    <div className="item-image">
                                                        <img alt={`imgItem${item.NFTID}`} src={item.ThumbCdn} />
                                                    </div>
                                                </Link>
                                                <div className="item-info">
                                                    <div className="item-content">
                                                        <Link
                                                            to={`/nfts/marketplaces/${item.CollectionSeoName}/${item.NFTID}`}
                                                        >
                                                            <Row
                                                                style={{
                                                                    marginTop: 8,
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {item?.Author ? (
                                                                    <Col className="price" xs={24} lg={25}>
                                                                        <p className="item-title fs-small fw-medium">
                                                                            {`${item.Name}`} by {item?.Author}
                                                                        </p>
                                                                    </Col>
                                                                ) : (
                                                                    <Col className="price" xs={12} lg={14}>
                                                                        <p className="item-title fs-small fw-medium">
                                                                            {`${item.Name}`}
                                                                        </p>
                                                                    </Col>
                                                                )}
                                                                {item?.Ranking > 0 ? (
                                                                    <Col className="icon-price rarity" xs={12} lg={10}>
                                                                        {iconImage} {item?.Ranking}
                                                                    </Col>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </Row>
                                                        </Link>
                                                        <Row
                                                            style={{
                                                                marginTop: 8,
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Col className="price" xs={12} lg={14}>
                                                                <button
                                                                    className="price-btn cursor-pointer"
                                                                    type="button"
                                                                >
                                                                    <Image
                                                                        className="icon-bnb"
                                                                        wrapperStyle={{ width: 20 }}
                                                                        preview={false}
                                                                        src="https://cdn.infinitelaunch.io/token-logos/svg/bnb.svg"
                                                                    />
                                                                    {price} BNB{' '}
                                                                </button>
                                                            </Col>

                                                            {item?.Rarity > 0 ? (
                                                                <Col className="icon-price rarity" xs={12} lg={10}>
                                                                    {/* Rarity: {item?.Rarity.toFixed(2)} */}
                                                                    <Styled>
                                                                        {item.IsFavorited ? (
                                                                            <Tooltip
                                                                                title="Unfavorite"
                                                                                getPopupContainer={(trigger) => {
                                                                                    return trigger;
                                                                                }}
                                                                            >
                                                                                <HeartFilled
                                                                                    className="icon-heart"
                                                                                    onClick={(e) =>
                                                                                        onChangeFavorite(
                                                                                            e,
                                                                                            item.IsFavorited,
                                                                                            item?.ID,
                                                                                            'Unfavorite',
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip
                                                                                title="Favorite"
                                                                                getPopupContainer={(trigger) => {
                                                                                    return trigger;
                                                                                }}
                                                                            >
                                                                                <HeartOutlined
                                                                                    className="icon-heart"
                                                                                    onClick={(e) =>
                                                                                        onChangeFavorite(
                                                                                            e,
                                                                                            item.IsFavorited,
                                                                                            item?.ID,
                                                                                            'Favorite',
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </Styled>
                                                                    <span style={{ marginLeft: '5px' }}>
                                                                        {item?.Favorites || 0}
                                                                    </span>
                                                                </Col>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                            {totalListNfts > 0 ? (
                                <div className="top-list-filter">
                                    <Pagination
                                        current={_page}
                                        pageSize={_pageSize}
                                        total={totalListNfts}
                                        onChange={onChangePage}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </Content>
            </Row>
        </Wrapper>
    );
});

export default MyWallet;
