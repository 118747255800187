import React, { memo } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Wrapper } from 'src/modules/Auth/Auth.styled';
import { BTNPrimary } from 'src/components/Button';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import PrimaryText from 'src/components/PrimaryText';
import { actionFree } from './Auth.actions';

interface IAuthSuccess {
    title: string;
    desc: string;
    onSubmit?: () => void;
}

const AuthSuccess = ({ title, desc, onSubmit }: IAuthSuccess) => {
    const dispatch = useDispatch();
    const onBtnClick = () => {
        batch(() => {
            dispatch(actionCloseModal());
            dispatch(actionFree());
            typeof onSubmit === 'function' && onSubmit();
        });
    };
    return (
        <Wrapper>
            <PrimaryText className="fw-bold disable-pointer success-title">{title}</PrimaryText>
            <p className="fs-medium success-desc">{desc}</p>
            <BTNPrimary className="btn-success" title="OK" onClick={onBtnClick} />
        </Wrapper>
    );
};

export default memo(AuthSuccess);
