import { Reducer } from 'redux';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AirdropActions, AirdropActionType, ETypeToken, IAirdropState } from './Airdrop.type';

const initialState: IAirdropState = {
    loading: false,
    infoTokenSend: {
        address: '',
        isValidErc20: false,
        symbol: '',
        balance: '',
        decimals: 0,
    },
    recipients: [],
    errorMsg: '',
    typeToken: ETypeToken.ERC20,
};

const airdropReducer: Reducer<IAirdropState, AirdropActions> = (
    state = initialState,
    action: AirdropActions,
): IAirdropState => {
    switch (action.type) {
        case AirdropActionType.LOADING: {
            const { loading } = action.payload;
            return {
                ...state,
                loading,
            };
        }
        case AirdropActionType.SET_INFO_TOKEN_SEND: {
            const { infoTokenSend } = action.payload;
            return {
                ...state,
                infoTokenSend,
            };
        }
        case AirdropActionType.SET_RECIPIENT: {
            const { recipients } = action.payload;
            return {
                ...state,
                recipients,
            };
        }
        case AirdropActionType.SET_ERROR: {
            const { errorMsg } = action.payload;
            return {
                ...state,
                errorMsg,
            };
        }
        case AirdropActionType.SET_TYPE_TOKEN: {
            const { typeToken } = action.payload;
            return {
                ...state,
                typeToken,
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'airdrop',
    storage,
    stateReconciler: autoMergeLevel2,
};
export default persistReducer(persistConfig, airdropReducer as any);
// export default airdropReducer;
