import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/nft-analytics/watch-list/view';

const Pool: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/NftTracking/WatchList')),
    name: 'WatchList NFTs',
};

export default Pool;
