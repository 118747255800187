import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/nfts/collections/bony-bastards';

const MintNFT: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/MintNFT')),
    name: 'MindNFT',
};

export default MintNFT;
