/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Popconfirm } from 'antd';
import { ReactComponent as ArrowUp } from 'src/assets/svg/_arrow_up.svg';
import { ReactComponent as ArrowDown } from 'src/assets/svg/_arrow_down.svg';
import format from 'src/utils/format';
import { useDispatch } from 'react-redux';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { actionOpenLoading, actionCloseLoading } from 'src/components/Loading/Loading.actions';
import {
    apiGetWatchListByUser,
    apiGetNftTrackingDetails,
    apiGetNftTrackingDailyStats,
    apiGetNftTrackingHistory,
    apiUpdateWatchList,
} from 'src/modules/NftTracking/redux/NftTracking.service';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Wrapper } from 'src/modules/NftTracking/WatchList.styled';
import PrimaryText from 'src/components/PrimaryText';

const LIMIT = 20;

const WatchList = () => {
    const [data, setData] = useState<any>([]);
    const [dataDailyOhlcv, setDataDailyOhlcv] = useState<any>([]);
    const [dataHistory, setDataHistory] = useState<any>([]);
    const [dataWatchList, setDataWatchList] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const { account } = useActiveWeb3React();
    const dispatch = useDispatch();
    const [paginating, setPaginating] = React.useState({
        current: 1,
        pageSize: LIMIT,
        total: 0,
    });
    const [paginatingWatchList, setPaginatingWatchList] = React.useState({
        current: 1,
        pageSize: LIMIT,
        total: 0,
    });

    const loadWatchListDetails = async (address: any) => {
        dispatch(actionOpenLoading());
        const watchListNftDetail: any = await apiGetNftTrackingDetails(address);
        setData(watchListNftDetail?.Result?.data?.collection);

        const resultDailyStats: any = await apiGetNftTrackingDailyStats(address);
        // setDataDailyStats(resultDailyStats?.Result?.data?.collection);

        const resultHistory: any = await apiGetNftTrackingHistory(address);
        if (resultHistory?.Result?.data?.collection.orderHistory) {
            let histories = {
                priceInEth: [],
            } as any;
            for (let i = 0; i < resultHistory?.Result?.data?.collection?.orderHistory.length; i += 1) {
                let history = resultHistory?.Result?.data?.collection?.orderHistory[i];
                histories.priceInEth.push([history.timestamp, history.priceInEth]);
            }

            setDataHistory(histories);
        }
        if (resultDailyStats?.Result?.data?.collection?.ohlcv) {
            let ohlsvs = {
                average: [],
                floor: [],
                numOfSales: [],
                volume: [],
                time: [],
            } as any;
            for (let i = 0; i < resultDailyStats?.Result?.data?.collection?.ohlcv.length; i += 1) {
                let ohlsv = resultDailyStats?.Result?.data?.collection?.ohlcv[i];
                ohlsvs.average.push([ohlsv.timestamp, ohlsv.average]);
                ohlsvs.floor.push([ohlsv.timestamp, ohlsv.close]);
                ohlsvs.numOfSales.push([ohlsv.timestamp, ohlsv.count]);
                ohlsvs.volume.push([ohlsv.timestamp, ohlsv.volume]);
                ohlsvs.time.push(ohlsv.timestamp);
            }
            setDataDailyOhlcv(ohlsvs);
        }
        dispatch(actionCloseLoading());
    };

    const calculatorChart = (number: any, oldNumber: any) => {
        let result: any = (number / oldNumber - 1) * 100;
        if (result !== 0) {
            result = format.toFixed({ number: result, decimals: 2 });
        }
        if (result === 'NaN' || result === 'Infinity' || result === 0) {
            result = 0;
        }
        if (result < 0) {
            result = Math.abs(result);
        }
        return result;
    };

    const onMenuClick = (address: string) => {
        // setAddressSelected(address);
        loadWatchListDetails(address);
    };

    const loadData = async () => {
        setLoading(true);
        const resultDataWatchList = await apiGetWatchListByUser(account);
        if (resultDataWatchList?.Result?.Data) {
            setDataWatchList(resultDataWatchList?.Result?.Data);
            loadWatchListDetails(resultDataWatchList?.Result?.Data[0]?.address);
            // setAddressSelected(resultDataWatchList?.Result?.Data[0]?.address);
        }
        setPaginatingWatchList({
            current: 1,
            pageSize: LIMIT,
            total: resultDataWatchList?.Result?.Total,
        });

        setLoading(false);
    };
    const handleDelete = async (address: string) => {
        await apiUpdateWatchList(address, account, true);
        loadData().then();
    };

    const columnsWatchList = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            render: (symbol: any, row: any) => (
                <div
                    className="text-headline"
                    onClick={() => {
                        onMenuClick(row.address);
                    }}
                >
                    <p className="number-chart">
                        {row?.symbol}
                        <div className="flex flex-row items-center">
                            <span className="arrow-status" style={{ marginLeft: 0 }}>
                                {row?.name}
                            </span>
                        </div>
                    </p>
                </div>
            ),
        },
        {
            title: 'Floor',
            dataIndex: 'minPriceInEth',
            sorter: (a: any, b: any) => a.dailyStats.minPriceInEth - b.dailyStats.minPriceInEth,
            render: (minPriceInEth: any, row: any) => (
                <div
                    className="text-headline"
                    onClick={() => {
                        onMenuClick(row.address);
                    }}
                >
                    <p className="number-chart">
                        <span className="">Ξ</span> {row?.dailyStats?.minPriceInEth}
                        <div className="flex flex-row items-center">
                            {row?.dailyStats?.minPriceInEth >= row?.dailyStats?.previousStats?.minPriceInEth ? (
                                <ArrowUp />
                            ) : (
                                <ArrowDown />
                            )}
                            <span
                                className={`arrow-status ${
                                    row?.dailyStats?.minPriceInEth >= row?.dailyStats?.previousStats?.minPriceInEth
                                        ? 'arrow-up'
                                        : 'arrow-down'
                                }`}
                            >
                                {calculatorChart(
                                    row?.dailyStats?.minPriceInEth,
                                    row?.dailyStats?.previousStats?.minPriceInEth,
                                )}
                                %
                            </span>
                        </div>
                    </p>
                </div>
            ),
        },
        {
            title: 'Sales',
            dataIndex: 'numberOfSales',
            key: 'numberOfSales',
            sorter: (a: any, b: any) => a?.dailyStats?.numberOfSales - b?.dailyStats?.numberOfSales,
            render: (numberOfSales: any, row: any) => (
                <div
                    className="text-headline"
                    onClick={() => {
                        onMenuClick(row.address);
                    }}
                >
                    <p className="number-chart">
                        <span className="">Ξ</span> {row?.dailyStats?.numberOfSales}
                        <div className="flex flex-row items-center">
                            {row?.dailyStats?.numberOfSales >= row.deltaStats?.previousStats?.numberOfSales ? (
                                <ArrowUp />
                            ) : (
                                <ArrowDown />
                            )}
                            <span
                                className={`arrow-status ${
                                    row?.dailyStats?.numberOfSales >= row?.dailyStats?.previousStats?.numberOfSales
                                        ? 'arrow-up'
                                        : 'arrow-down'
                                }`}
                            >
                                {calculatorChart(
                                    row?.dailyStats?.numberOfSales,
                                    row?.dailyStats?.previousStats?.numberOfSales,
                                )}
                                %
                            </span>
                        </div>
                    </p>
                </div>
            ),
        },
        {
            title: 'Volume',
            dataIndex: 'volumeInEth',
            sorter: (a: any, b: any) => a?.dailyStats?.volumeInEth - b?.dailyStats?.volumeInEth,
            render: (volumeInEth: any, row: any) => (
                <div
                    className="text-headline"
                    onClick={() => {
                        onMenuClick(row.address);
                    }}
                >
                    <p className="number-chart">
                        <span className="">Ξ</span> {row?.dailyStats?.volumeInEth}
                        <div className="flex flex-row items-center">
                            {row?.dailyStats?.volumeInEth >= row?.deltaStats?.previousStats?.volumeInEth ? (
                                <ArrowUp />
                            ) : (
                                <ArrowDown />
                            )}
                            <span
                                className={`arrow-status ${
                                    row?.dailyStats?.volumeInEth >= row?.dailyStats?.previousStats.volumeInEth
                                        ? 'arrow-up'
                                        : 'arrow-down'
                                }`}
                            >
                                {calculatorChart(
                                    row?.dailyStats?.volumeInEth,
                                    row?.dailyStats?.previousStats.volumeInEth,
                                )}
                                %
                            </span>
                        </div>
                    </p>
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            render: (volumeInEth: any, row: any) =>
                dataWatchList.length >= 1 ? (
                    <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(row.address)}>
                        Delete
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setPaginating(pagination);
        loadData().then();
    };

    useEffect(() => {
        loadData().then();
    }, []);

    const getChartsHistory = (dataHistories: any) => {
        let dataChart = dataHistories;

        const options = {
            chart: {
                type: 'scatter',
                backgroundColor: 'transparent',
                zoomType: 'xy',
                height: 544,
            },
            title: {
                text: `${data?.name} sales history`,
                style: {
                    color: '#E6E8EC',
                    fontSize: '19px',
                },
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                        },
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false,
                            },
                        },
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '{point.y} ETH',
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: '',
                    color: 'rgba(119, 152, 191, .5)',
                    data: dataChart.priceInEth,
                },
            ],
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />;
    };

    const getChartsAverageFloor = (dataHistories: any) => {
        let dataChart = dataHistories;

        const options = {
            chart: {
                type: 'line',
                backgroundColor: 'transparent',
                height: 544,
            },
            title: {
                text: `${data.name} sales floor price, average price, number of sales and volume`,
                style: {
                    color: '#E6E8EC',
                    fontSize: '19px',
                },
            },
            legend: {
                enabled: true,
                itemStyle: {
                    color: '#E6E8EC',
                    fontSize: '12px',
                },
                itemHoverStyle: {
                    color: '#E6E8EC',
                },
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                        },
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false,
                            },
                        },
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: 'Average',
                    color: 'rgba(151, 87, 215, 1)',
                    data: dataChart.average,
                },
                {
                    name: 'Sale floor',
                    color: 'rgba(69, 178, 107, 1)',
                    data: dataChart.floor,
                },
            ],
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />;
    };

    const getChartsNumberOfSalesVolume = (dataHistories: any) => {
        let dataChart = dataHistories;

        const options = {
            chart: {
                type: 'line',
                backgroundColor: 'transparent',
                height: 544,
            },
            title: {
                text: ``,
                style: {
                    color: '#E6E8EC',
                    fontSize: '19px',
                },
            },
            legend: {
                enabled: true,
                itemStyle: {
                    color: '#E6E8EC',
                    fontSize: '12px',
                },
                itemHoverStyle: {
                    color: '#E6E8EC',
                },
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                        },
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false,
                            },
                        },
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '{point.y} ETH',
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: 'Number of Sales',
                    color: 'rgba(166, 90, 89, 1)',
                    data: dataChart.numOfSales,
                },
                {
                    name: 'Volume',
                    type: 'column',
                    color: 'rgba(196, 196, 196, 1)',
                    data: dataChart.volume,
                },
            ],
        };
        return <HighchartsReact highcharts={Highcharts} options={options} />;
    };

    return (
        <Wrapper>
            <Col className="hero-text" lg={24} xs={24} style={{ width: '100%', marginBottom: '20px', marginTop: 40 }}>
                <Col className="hero-text" lg={7} xs={24} style={{ width: '100%' }}>
                    <PrimaryText className="leader-board-head-title">Watchlists</PrimaryText>
                </Col>
                <p className="fs-regular fw-regular hero-content-subtext">
                    Watch up to 20 contracts or wallets per watchlist, unlimited watchlists!
                </p>
            </Col>
            <Row style={{ width: '100%' }} className="group-watchlist">
                <Col lg={8} xs={24}>
                    <Table
                        columns={columnsWatchList}
                        dataSource={dataWatchList}
                        pagination={paginatingWatchList}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </Col>
                {dataHistory?.priceInEth ? (
                    <Col lg={14} xs={24}>
                        <Row className="chart-history">
                            <div className="container">{getChartsHistory(dataHistory)}</div>
                        </Row>
                        <Row className="chart-history" style={{ marginTop: 30 }}>
                            <div className="container">{getChartsAverageFloor(dataDailyOhlcv)}</div>
                        </Row>
                        <Row className="chart-history">
                            <div className="container">{getChartsNumberOfSalesVolume(dataDailyOhlcv)}</div>
                        </Row>
                    </Col>
                ) : (
                    ''
                )}
            </Row>
        </Wrapper>
    );
};
export default WatchList;
