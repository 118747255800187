import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/iframe/nft-ido/:id';

const IframeNft: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/IframeNftIdo')),
    name: 'NFT Ido',
};

export default IframeNft;
