/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-autofocus */
import { Col, Row, Button } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CircleCloseIcon } from 'src/components/Icons';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import styled, { ITheme } from 'styled-components';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import PrimaryText from 'src/components/PrimaryText';
import { unlockV2 } from '../redux/Account.service';
import { getILABalanceAction, getAccountLockTierInfo } from '../redux/Account.action';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }: { theme: ITheme }) => theme.dark1};
    > div {
        width: 100%;
    }
    .box-1 {
        width: 100%;
    }
    .btn-close {
        margin: auto 0 32px auto;
    }
    .header-title {
        margin: auto;
        font-style: normal;
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
    }
    .body-modal {
        padding: 24px;
        background: ${({ theme }: { theme: ITheme }) => theme.background1};
        .box-balance {
            display: flex;
            flex: 1;
            margin-bottom: 16px;
            justify-content: flex-end;
            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #c4c4c4;
            }
        }
        .box-content {
            display: grid;
            padding: 8px 12px;
            width: 100%;
            border-radius: 12px;
        }
        .box-title-head {
            font-style: normal;
            font-weight: 100;
            font-size: 14px;
            color: #fcfcfd;
        }
        .box-title-sub {
            font-style: normal;
            font-size: 14px;
            color: ${({ theme }: { theme: ITheme }) => theme.text3};
            margin-top: 20px;
            margin-right: 16px;
        }
        .btn:hover {
            transform: scale(1.03);
        }

        .box-title-sub1 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #fcfcfd;
            margin-top: 6px;
            cursor: pointer;
        }

        .box-button {
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            .btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                height: 40px;
                background: ${({ theme }: { theme: ITheme }) => theme.text3};
                border: none;
                font-style: normal;
                font-size: 14px;
                text-align: center;
                color: ${({ theme }: { theme: ITheme }) => theme.text};
                border-radius: 8px;
                width: 48%;
                font-weight: 600;
            }

            .btn-cancel {
                background: gray;
            }
        }
    }
    .header-modal {
        display: flex;
        align-items: flex-start;
        padding: 24px;
        height: 80px;
        background: #353945;
        border: 1px solid #353945;
        box-sizing: border-box;
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        border-radius: 8px 8px 0px 0px;
    }

    .cursor-pointer {
        cursor: pointer;
    }
    .cursor-pointer:hover {
        transform: scale(1.05);
    }
    .box-1-button {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        height: 32px;
        margin-top: 8px;
        button {
            font-style: normal;
            font-size: 12px;
            text-align: center;
            color: #fcfcfd;
        }
    }
    .active {
        background: ${({ theme }: { theme: ITheme }) => theme.text3};
        border: 2px solid ${({ theme }: { theme: ITheme }) => theme.text3};
    }
    .box-2-content {
        display: flex;
        padding: 8px 12px;
        margin-top: 16px;
        height: 52px;
        background: #353945;
        width: 100%;
        border-radius: 12px;
        justify-content: space-between;

        .box-title-head {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            color: ${({ theme }: { theme: ITheme }) => theme.text};
            .input {
                width: 100%;
                color: ${({ theme }: { theme: ITheme }) => theme.text1};
            }
        }
        .box-title-sub {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: ${({ theme }: { theme: ITheme }) => theme.text3};
            margin-top: 6px;
            margin-left: 16px;
            margin-right: 16px;
            cursor: pointer;
        }
    }
    .total-locked {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .total {
            font-weight: 500;
            font-size: 12px;
            color: #fcfcfd;
            margin-left: 3px;
            margin-top: 5px;
        }
        .last-time-stake {
            font-weight: 500;
            font-size: 12px;
            color: #fcfcfd;
            margin-left: 3px;
            margin-top: 5px;
        }
    }
`;

const ModalUnlockTierV2 = React.memo((props: any) => {
    const [amountIla, setAmountIla] = useState<any>(0);
    const { account, library } = useActiveWeb3React();
    let { contractAddress } = props;
    const { amount, lastStakeTime }: any = useSelector((state: any) => state.account);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(actionCloseModal());
    };
    const dateFormat = moment(new Date(lastStakeTime * 1000)).format('DD-MM-YYYY');

    const handleConfirm = async () => {
        if (amountIla <= 0) {
            return;
        }
        setLoading(true);
        const result = await unlockV2(amountIla, contractAddress, library, account);
        try {
            if (!result) {
                throw new Error();
            }

            dispatch(
                addToast({
                    type: TOAST_TYPES.WAITING,
                    title: 'Waiting!',
                    description: 'Your transaction processing.',
                }),
            );

            await result.wait();

            dispatch(
                addToast({
                    type: TOAST_TYPES.SUCCESS,
                    title: 'Well done!',
                    description: 'Your transaction successfully.',
                }),
            );

            dispatch(getILABalanceAction(account));
            dispatch(getAccountLockTierInfo(account, contractAddress));
            setLoading(false);
            dispatch(actionCloseModal());
        } catch (error: any) {
            setLoading(false);
            console.log('error: ', error);
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'Oh snap!',
                    description: 'Change a few things up and try submitting again.',
                }),
            );
        }
    };
    function changeCurrency(e: any) {
        if (e.target.value.match('^[0-9]*[.,]?[0-9]{0,18}$') != null) {
            setAmountIla(e.target.value);
            e.preventDefault();
        }
    }
    return (
        <Wrapper>
            <Row className="header-modal">
                <Col className="hero-text" lg={17} xs={17}>
                    <PrimaryText className="fw-bold fs-verylarge disable-pointer header-title">Unlock Tier</PrimaryText>
                </Col>
                <Col className="hero-text" lg={7} xs={7}>
                    <CircleCloseIcon onClick={handleClose} className="btn-close" />
                </Col>
            </Row>
            <Row className="body-modal">
                <div className="box-content">
                    <Col className="hero-text" lg={24} xs={24}>
                        <p className="box-title-head">
                            Unlocking ILA earlier than 60 days will result in certain percentages of token burn. Please
                            refer to Tier Ranking Disclaimer in your account page for more information.
                        </p>
                    </Col>
                </div>
                <div className="box-2-content">
                    <p className="box-title-head">
                        <input
                            className="input"
                            type="text"
                            pattern="^[0-9]*[.,]?[0-9]{0,18}$"
                            min="0"
                            autoFocus
                            placeholder="0.00"
                            value={amountIla}
                            onChange={changeCurrency}
                        />
                    </p>
                    <div className="box-title-sub" onClick={() => setAmountIla(`${amount}`)}>
                        MAX
                    </div>
                </div>
                <div className="total-locked">
                    <div className="total">Total locked: {amount.toLocaleString()} ILA</div>
                    <div className="last-time-stake">Last time staked: {dateFormat}</div>
                </div>
                <div className="box-content">
                    <Col className="hero-text" lg={24} xs={24}>
                        <div className="box-title-sub">
                            <p>Do you wish to continue?</p>
                        </div>
                    </Col>
                </div>
                <div className="box-button">
                    <Button onClick={handleClose} className="btn btn-cancel">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} loading={loading} className="btn">
                        Confirm
                    </Button>
                </div>
            </Row>
        </Wrapper>
    );
});

export default ModalUnlockTierV2;
