import { apiGetEarn, apiPriceToken } from 'src/modules/StakeFarming/redux/StakeFarming.service';

export const ACTION_TYPES = {
    GET_EARN: 'GET_EARN',
    GET_PRICE_ILA: 'GET_PRICE_ILA',
};
export const actionGetEarning = (type: any) => async (dispatch: any) => {
    try {
        let typeFilter = type;
        if (type === 'pools') {
            typeFilter = 'stake';
        } else {
            typeFilter = 'farm';
        }
        const list: any = await apiGetEarn(typeFilter);
        dispatch({
            type: ACTION_TYPES.GET_EARN,
            payload: { data: list.Items || [], total: list.Total },
        });
    } catch (error) {
        console.log('error: ', error);
    }
};
export const actionGetPriceIla = (symbol: any) => async (dispatch: any) => {
    try {
        const result: any = await apiPriceToken(symbol);
        dispatch({
            type: ACTION_TYPES.GET_PRICE_ILA,
            payload: { data: result.data || [] },
        });
    } catch (error) {
        console.log('actionGetPriceIla error: ', error);
    }
};
