import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { getContract } from 'src/functions/getContract';
import { Web3Provider } from '@ethersproject/providers';
import { ENVS } from '../../../configs/Configs.env';
import contractILA from '../../../contracts/ila.json';
import contractLockTier from '../../../contracts/LockTierV2.json';

declare global {
    interface Window {
        ethereum: any;
    }
}
const provideUrl = ENVS.REACT_APP_IS_MAINNET
    ? 'https://bsc-dataseed.binance.org/'
    : 'https://data-seed-prebsc-1-s2.binance.org:8545/';

export const getILABalanceV2 = async (address: string) => {
    const contractABI: any = contractILA.abi;
    const contractAddr: any = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);

    try {
        const ilaInWallet = await contract.methods.balanceOf(address).call();
        const lockInfo = await contract.methods.checkBalanceLocked(address).call();
        return { ilaInWallet, lockInfo };
    } catch (error) {
        console.log('lockInfo error: ', error);
        throw new Error();
    }
};

export const getILABalance = async (address: string) => {
    const contractABI: any = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);

    try {
        const ilaInWallet = await contract.methods.balanceOf(address).call();
        const lockInfo = await contract.methods.checkBalanceLocked(address).call();
        return { ilaInWallet, lockInfo };
    } catch (error) {
        console.log('lockInfo error: ', error);
        throw new Error();
    }
};

export const apiGetAccountLockTierInfo = async (address: string, contractAddr: string) => {
    const contractABI: any = contractLockTier;
    const web3 = new Web3(new Web3.providers.HttpProvider(provideUrl));
    const contract: any = new web3.eth.Contract(contractABI, contractAddr);
    try {
        const accountInfo = await contract.methods.getAccountInfo(address).call();
        return { accountInfo };
    } catch (error) {
        console.log('getAccountLockTierInfo error: ', error);
        throw new Error();
    }
};

export const createLockV2 = async (
    amount: number,
    contractAddr: string,
    library: Web3Provider | undefined,
    account?: string,
) => {
    if (!library || !account) {
        throw new Error('provider or account not found');
    }

    const contractABI = contractLockTier;
    const contract = getContract(contractAddr, contractABI, library, account);

    try {
        const amountLock = ethers.utils.parseEther(amount.toString());
        const tx = await contract.stake(amountLock);

        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const unlockV2 = async (
    amount: number,
    contractAddr: string,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!library || !account) {
        throw new Error('provider or account not found');
    }
    const contractABI = contractLockTier;
    const contract = getContract(contractAddr, contractABI, library, account);

    try {
        const amountUnLock = ethers.utils.parseEther(amount.toString());
        const tx = await contract.unstake(amountUnLock);
        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const claim = async (contractAddr: string, library: Web3Provider | undefined, account: string) => {
    if (!library || !account) {
        throw new Error('provider or account not found');
    }
    const contractABI: any = contractLockTier;
    const contract = getContract(contractAddr, contractABI, library, account);
    try {
        const tx = await contract.claimReward();
        return tx;
    } catch (error) {
        console.log('claim error: ', error);
        throw error;
    }
};

export const approveILA = async (contractAddress: string, library: Web3Provider | undefined, account?: string) => {
    if (!library || !account) {
        throw new Error('provider or account not found');
    }
    const contractABIILA = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;
    const contract = getContract(contractAddr, contractABIILA, library, account);

    try {
        const tx = await contract.approve(contractAddress, ethers.constants.MaxUint256);
        return tx;
    } catch (error: any) {
        console.log('error: ', error);
    }
};

export const checkApproveILA = async (
    contractAddress: string,
    addressUser: string,
    library: Web3Provider | undefined,
) => {
    if (!library) {
        throw new Error('provider not found');
    }
    const contractABIILA = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;
    const contract = getContract(contractAddr, contractABIILA, library, addressUser);

    try {
        const allowance = await contract.allowance(addressUser, contractAddress);
        const userAllowance = new BigNumber(allowance.toString()).div(10 ** 18).toNumber();
        return userAllowance;
    } catch (error: any) {
        console.log('error: ', error);
    }
};

export const createLock = async (
    amount: number,
    library: Web3Provider | undefined,
    account: string | null | undefined,
) => {
    if (!library || !account) {
        throw new Error('provider or account not found');
    }
    const contractABI = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const contract = getContract(contractAddr, contractABI, library, account);

    try {
        const amoutLock = ethers.utils.parseEther(amount.toString());
        const tx = await contract.createLock(amoutLock);

        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const unlock = async (library: Web3Provider | undefined, account: string | null | undefined) => {
    if (!library || !account) {
        throw new Error('provider or account not found');
    }
    const contractABI = contractILA.abi;
    const contractAddr = ENVS.REACT_APP_IS_MAINNET ? contractILA.address.mainnet : contractILA.address.testnet;

    const contract = getContract(contractAddr, contractABI, library, account);

    try {
        const tx = await contract.withdrawVested();

        return tx;
    } catch (error) {
        console.log('error: ', error);
        return null;
    }
};

export const apiGetProfileInfo = async (isCheckKYC: number, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/profile?check-kyc=${isCheckKYC}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateAccount = async (data: any, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/update`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetStatusKYCFromBlockPass = (kycRefId: string) => {
    const apiKey = ENVS.REACT_APP_BLOCK_PASS_API_KEY;
    const apiURL = `https://kyc.blockpass.org/kyc/1.0/connect/${ENVS.REACT_APP_BLOCK_PASS_CLIENT_ID}/refId/${kycRefId}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${apiKey}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateOtherWallet = async (data: { Wallets: any }, token: string) => {
    const apiURL = `${ENVS.REACT_APP_BASE_API}auth/update`;
    const parameters = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
