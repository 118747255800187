import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/all-lock';

const VestingRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('./AllLockTokenVesting')),
    name: 'Army',
};

export default VestingRoute;
