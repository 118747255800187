import styled, { ITheme } from 'styled-components';

export const DrawerMenuDesktop = styled.div`
    color: ${({ theme }: { theme: ITheme }) => theme.text4};
    .dropdown-wrapper {
        display: flex;
        flex-direction: column;
        padding: 30px;
        .dropdown-bottom {
            width: 100%;
            .guaranteed-allocation {
                display: flex;
                flex-direction: row;
                width: 100%;
                .guaranteed-allocation-img {
                    width: 271px;
                    height: 157px;
                }
                .guaranteed-allocation-content {
                    width: 38%;
                    display: flex;
                    flex-direction: column;
                    margin-left: 16px;
                    .text-guaranteed {
                        color: ${({ theme }: { theme: ITheme }) => theme.text5};
                        margin-bottom: 8px;
                    }
                    .hint-guaranteed {
                        color: ${({ theme }: { theme: ITheme }) => theme.text6};
                        margin-bottom: 32px;
                    }
                    .how-it-work {
                        width: 100%;
                        span {
                            padding-bottom: 2px;
                            color: ${({ theme }: { theme: ITheme }) => theme.text3};
                            border-bottom: 1px solid ${({ theme }: { theme: ITheme }) => theme.border1};
                        }
                        &:hover {
                            transform: scale(1.01);
                        }
                    }
                }
            }
        }
        .dropdown-top {
            display: flex;
            /* flex-direction: row;
            justify-content: space-between; */
            .top-service {
                display: flex;
                flex-direction: column;
                width: 75%;
                .service-wrapper {
                    display: flex;
                    flex-direction: column;
                    margin-top: 8px;
                    margin-left: 12px;
                    padding-left: 16px;
                    position: relative;
                    border-left: 1px solid #6a6c7b5f;
                    .item-dropdown {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 32px;
                        .icon svg {
                            width: 20px;
                            height: 20px;
                            fill: ${({ theme }: { theme: ITheme }) => theme.headerRow};
                        }
                        .item-text {
                            margin-left: 8px;
                            .text {
                                cursor: pointer;
                                color: ${({ theme }: { theme: ITheme }) => theme.text4};
                                &:hover {
                                    color: ${({ theme }: { theme: ITheme }) => theme.text7};
                                }
                            }
                            .hint {
                                margin-top: 8px;
                                cursor: pointer;
                                color: ${({ theme }: { theme: ITheme }) => theme.text4};
                                &:hover {
                                    color: ${({ theme }: { theme: ITheme }) => theme.text3};
                                }
                            }
                        }
                        :last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            .top-button {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .top-item {
                    margin-bottom: 32px;
                    color: ${({ theme }: { theme: ITheme }) => theme.text4};
                    :last-child {
                        margin-bottom: 0px;
                    }
                    &:hover {
                        color: ${({ theme }: { theme: ITheme }) => theme.text7};
                    }
                }
            }
        }
        .dropdown-middle {
            margin: 50px 0;
            .box-upgrade-tier {
                border-radius: 4px;
                height: 64px;
                width: 100%;
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(87,255,103,1)' stroke-width='1' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square' /%3e%3c/svg%3e");
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .left,
                .right {
                    color: ${({ theme }: { theme: ITheme }) => theme.text3};
                    margin-left: 20px;
                }
                .right {
                    margin-right: 20px;
                    svg {
                        height: 16px;
                        width: 16px;
                        margin-right: 6px;
                    }
                }
                &:hover {
                    transform: scale(1.01);
                }
            }
        }
    }
`;
