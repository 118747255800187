import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { getAccountLockTierInfo, getILABalanceAction } from 'src/modules/Accounts/redux/Account.action';
import { isLoginSelector } from 'src/modules/Auth/Auth.selector';
import { apiGetSettings } from 'src/modules/Collections/redux/Collections.service';
import { actionSignIn, actionUpdateAccessToken } from 'src/redux/walletAccount/WalletAccount.action';

const enhanceSignInWallet = (WrappedComponent: FunctionComponent) => (props: any) => {
    const dispatch = useDispatch();
    const { account } = useActiveWeb3React();
    const isLogin = useSelector(isLoginSelector);
    const onSignInWallet = () => {
        dispatch(actionSignIn(account as string));
    };
    const getLockTierContract = async () => {
        const result: any = await apiGetSettings('locked_tier_contract_address');
        if (!result) {
            console.error('Cannot get locked_tier_contract_address');
        }
        const contract = result.Data;
        dispatch(getILABalanceAction(account));
        dispatch(getAccountLockTierInfo(account, contract));
        // actionClearAccessToken
    };
    const clearTokenWallet = () => {
        dispatch(
            actionUpdateAccessToken({
                address: '',
                token: '',
                nonce: 0,
            }),
        );
    };
    React.useEffect(() => {
        if (account) {
            getLockTierContract();
        } else {
            clearTokenWallet();
        }
        if (!isLogin) {
            clearTokenWallet();
        }
    }, [account, isLogin]);

    return (
        <WrappedComponent
            {...{
                ...props,
                onSignInWallet,
                clearTokenWallet,
            }}
        />
    );
};

export default enhanceSignInWallet;
