import { ENVS } from 'src/configs/Configs.env';

export const apiGetNftTracking = async () => {
    let urlApi = `${ENVS.REACT_APP_BASE_API}analytics/trending?limit=1000`;
    const parameters = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(urlApi, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const apiGetNftTrackingDetails = async (address: string) => {
    let urlApi = `${ENVS.REACT_APP_BASE_API}analytics/detail?address=${address}`;
    const parameters = {
        method: 'GET',
        // headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: `Bearer ${window.token}`,
        // },
    };
    return fetch(urlApi, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetNftProfitLeaderBoard = async (address: string) => {
    let urlApi = `${ENVS.REACT_APP_BASE_API}analytics/detail-leaderboard?address=${address}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(urlApi, parameters)
        .then((response) => {
            if (!response.ok) {
                return {
                    statusCode: response.status,
                };
            }
            return response.json();
        })
        .then((data) => data);
};

export const apiGetNftTopBuyerLeaderBoard = async (address: string) => {
    let urlApi = `${ENVS.REACT_APP_BASE_API}analytics/detail-top-buyer?address=${address}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(urlApi, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetNftTopSellerLeaderBoard = async (address: string) => {
    let urlApi = `${ENVS.REACT_APP_BASE_API}analytics/detail-top-seller?address=${address}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(urlApi, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetNftTrackingDailyStats = async (address: string) => {
    let apiUrl = `${ENVS.REACT_APP_BASE_API}analytics/daily-stats?address=${address}`;
    const parameters = {
        method: 'GET',
    };
    return fetch(apiUrl, parameters)
        .then((response) => response.json())
        .then((data) => data);
};

export const apiGetNftTrackingHistory = async (address: string) => {
    let urlApi = `${ENVS.REACT_APP_BASE_API}analytics/history?address=${address}`;
    const parameters = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(urlApi, parameters)
        .then((response) => {
            if (!response.ok) {
                return {
                    statusCode: response.status,
                };
            }
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetNftTrackingOhlcv = async (address: string) => {
    let urlApi = `${ENVS.REACT_APP_BASE_API}analytics/ohlcv?address=${address}`;
    const parameters = {
        method: 'GET',
    };
    return fetch(urlApi, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateFavorites = async (nftAddress: any, userAddress: any, isRemove: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}analytics/favourite`;
    let method = 'POST';
    if (isRemove) {
        method = 'PUT';
    }
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
        body: JSON.stringify({ user_address: userAddress, nft_address: nftAddress }),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiUpdateWatchList = async (nftAddress: any, userAddress: any, isRemove: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}analytics/watchlist`;
    let method = 'POST';
    if (isRemove) {
        method = 'PUT';
    }
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
        body: JSON.stringify({ user_address: userAddress, nft_address: nftAddress }),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetFeeds = async (nftAddress: any, type: any, skip?: number) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}analytics/watchlist/logs`;
    let method = 'POST';
    if (skip) {
        apiURL += `?page=${skip}`;
    }
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
        body: JSON.stringify({ address: [nftAddress], type }),
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            if (!response.ok) {
                return {
                    statusCode: response.status,
                };
            }
            return response.json();
        })
        .then((data) => data);
};

export const apiGetFavoritesByUser = async (userAddress: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}analytics/favourite/${userAddress}`;
    let method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};
export const apiGetWatchListByUser = async (userAddress: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}analytics/watchlist/${userAddress}`;
    let method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => response.json())
        .then((json) => {
            return json;
        });
};
export const apiGetNftWatchListDetails = async (nftAddress: any, userAddress: any) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}analytics/watchlist/${userAddress}&nft_address=${nftAddress}`;
    let method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
};

export const apiGetListTokens = async (nftAddress: string, cursor?: string) => {
    let apiUrl = `${ENVS.REACT_APP_BASE_API}analytics/detail-token?address=${nftAddress}`;
    if (cursor) {
        apiUrl += `&after=${cursor}`;
    }
    const method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };

    return fetch(apiUrl, parameters)
        .then((response) => {
            return response.json();
        })
        .then((data) => data.Result.data.collection)
        .catch((error) => {
            console.log('apiGetListTokens error: ', error);
        });
};

export const getDetailMintData = async (nftAddress: string) => {
    let apiUrl = `${ENVS.REACT_APP_BASE_API}analytics/detail-mint-data?address=${nftAddress}`;
    const method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    console.log('parameters', parameters);

    return fetch(apiUrl, parameters)
        .then((response) => {
            if (!response.ok) {
                return {
                    statusCode: response.status,
                };
            }
            return response.json();
        })
        .then((data) => data);
};
interface ICursor {
    hasPreviousPage?: boolean;
    startCursor?: boolean;
    hasNextPage?: boolean;
    endCursor?: boolean;
}
export const getDetailMiners = async (nftAddress: string, cursor?: ICursor) => {
    let apiUrl = `${ENVS.REACT_APP_BASE_API}analytics/detail-minters?address=${nftAddress}`;
    if (cursor?.hasPreviousPage) {
        apiUrl += `&before=${cursor?.startCursor}`;
    }
    if (cursor?.hasNextPage) {
        apiUrl += `&after=${cursor?.endCursor}`;
    }
    const method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };

    return fetch(apiUrl, parameters)
        .then((response) => {
            return response.json();
        })
        .then((data) => data.Result.data.collection);
};

export const getHolderData = async (nftAddress: string) => {
    const apiUrl = `${ENVS.REACT_APP_BASE_API}analytics/detail-holder-data?address=${nftAddress}`;
    const method = 'GET';
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(apiUrl, parameters)
        .then((response) => {
            if (!response.ok) {
                return {
                    statusCode: response.status,
                };
            }
            return response.json();
        })
        .then((data) => data);
    // return fetch(apiUrl, parameters)
    //     .then((response) => response.json())
    //     .then((data) => data.Result.data.collection);
};

export const getDetailHolders = async (nftAddress: string, cursor?: ICursor) => {
    let apiURL = `${ENVS.REACT_APP_BASE_API}analytics/detail-holders?address=${nftAddress}`;
    const method = 'GET';
    if (cursor?.hasPreviousPage) {
        apiURL += `&before=${cursor?.startCursor}`;
    }
    if (cursor?.hasNextPage) {
        apiURL += `&after=${cursor?.endCursor}`;
    }
    const parameters = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.token}`,
        },
    };
    return fetch(apiURL, parameters)
        .then((response) => response.json())
        .then((data) => data.Result.data.collection);
};
